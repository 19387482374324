import React, { useState, useEffect } from 'react';
import { Card, Typography, Row, Col, Tooltip } from 'antd';
import { BarChartOutlined, LineChartOutlined, DotChartOutlined, AreaChartOutlined } from '@ant-design/icons';
import { ManualSpinnerLoader } from '../../../ManualSpinnerLoader';
import { getChopData } from '../../../Services';
import Navbar from '../../Navbar';
import { Utilities } from '../../../Utilities';
import { useNavigate } from 'react-router-dom';
const ReportsDashboard: React.FC = () => {
  const { Title, Text } = Typography;
  const navigate = useNavigate();
  const storageData: any = localStorage.getItem('StorageData');
  const parsedStorageData = storageData ? JSON.parse(storageData) : null;
  const [isLoading, setIsLoading] = useState(true);
  const utils = new Utilities();
  const [isHovered, setIsHovered] = useState<boolean[]>([false, false, false, false]);

  const fetchChopData = async () => {
    if (!parsedStorageData) {
      console.error("No storage data found");
      setIsLoading(true);
      return;
    }
    try {
      const fetchedData = await getChopData(
        parsedStorageData.jobRecId,
        parsedStorageData.startTime,
        parsedStorageData.endTime
      );

      if (fetchedData.length === 0) {
        console.warn("No data fetched");
        setIsLoading(true); // Re-enable loader if data is empty
      } else {
        const chopData = { recId: parsedStorageData.jobRecId, fetchedData };
        await utils.saveChopDataToIndexedDB(chopData);
        setIsLoading(false); // Set loader to false if data is fetched
      }
    } catch (err) {
      console.error("Error fetching data:", err);
      setIsLoading(true); // Re-enable loader if an error occurs
    }
  };

  useEffect(() => {
    // Initial 3-second delay to keep the loader visible initially
    const initialLoadingTimeout = setTimeout(() => {
      setIsLoading(false);
    }, 10000);

    // Fetch the data after the initial delay
    fetchChopData();

    // Cleanup the timeout on unmount
    return () => clearTimeout(initialLoadingTimeout);
  }, []);



  const handleMouseEnter = (index: number) => {
    setIsHovered(prevState => {
      const newState = [...prevState];
      newState[index] = true;
      return newState;
    });
  };

  const handleMouseLeave = (index: number) => {
    setIsHovered(prevState => {
      const newState = [...prevState];
      newState[index] = false;
      return newState;
    });
  };

  const onNavigate = (report: string, key?: string) => {


    navigate(`/${report}`);

    if (report === "reports") {
      localStorage.setItem('InitialReportKey', JSON.stringify(key));
      return;
    }



  };

  return (

    <>
      <Navbar page="reportsDashboard" />
      {isLoading ?
        (<div style={{ backgroundColor: '#1f1f1f', padding: '20px', minHeight: '91vh' }}>
          <ManualSpinnerLoader componentName={"ReportsDashboard"} />
        </div>)
        :
        (
          <div style={{ backgroundColor: '#1f1f1f', padding: '20px', minHeight: '91vh' }}>
            <Title style={{ color: '#e0e0e0', textAlign: 'center', marginBottom: '120px' }}>Select Report</Title>
            <Row gutter={16} justify="center">
              {[
                {
                  icon: <DotChartOutlined style={{ fontSize: '160px', color: '#ffffff', marginTop: '15px' }} />,
                  title: "Scatter Utilization By Channel",
                  description: "Distribution and intensity of signal usage across the frequency band.",
                  valueDescription: "Reveals patterns of spectrum utilization by identifying high and low activity areas",
                  isActive: false,
                  navigation: () => onNavigate("utilization")
                },
                {
                  icon: <BarChartOutlined style={{ fontSize: '160px', color: '#a3ffa3', marginTop: '15px' }} />,
                  title: "Utilization Visualizer",
                  description: "Highlights channel allocation across the frequency band.",
                  valueDescription: "Examines channel utilization and associated characteristics",
                  isActive: false,
                  navigation: () => onNavigate("utilization")
                },
                {
                  icon: <LineChartOutlined style={{ fontSize: '160px', color: '#f1c40f', marginTop: '15px' }} />,
                  title: "Channel Power",
                  description: "Identifies activity by channel over a specified period of time.",
                  valueDescription: "Displays typical utilization and helps to identify potential interference",
                  isActive: true,
                  navigation: () => onNavigate("reports", "3")
                },
                {
                  icon: <AreaChartOutlined style={{ fontSize: '160px', color: '#ff4d4f', marginTop: '15px' }} />,
                  title: "Occupancy",
                  description: "Illustrates the extent to which a given frequency channel is being utilized.",
                  valueDescription: "Offers feedback for optimizing signal performance",
                  isActive: true,
                  navigation: () => onNavigate("reports", "1")
                }
              ].map((item, index) => (

                <Col key={index} xs={24} sm={12} md={6}>
                  <Title level={4} style={{ color: '#e0e0e0', textAlign: 'center' }}>{item.title}</Title>

                  <Tooltip title={item.isActive ? item.valueDescription : "Disabled"}>
                    <Card
                      style={{
                        textAlign: 'center',
                        backgroundColor: '#2b2b2b',
                        height: '250px',
                        borderRadius: "10px",
                        boxShadow: isHovered[index] && item.isActive ? '0px 0px 10px rgba(255, 255, 255, 0.2)' : "none",
                        opacity: item.isActive ? 1 : 0.2, // Correctly set opacity based on isActive
                        cursor: item.isActive ? 'pointer' : 'not-allowed' // Adds a visual cue for disabled items
                      }}
                      onMouseEnter={item.isActive ? () => handleMouseEnter(index) : () => null}
                      onMouseLeave={item.isActive ? () => handleMouseLeave(index) : () => null}
                      onClick={item.isActive ? item.navigation : () => null}
                    >
                      {item.icon}
                    </Card>
                  </Tooltip>


                  <div style={{ minHeight: '80px', textAlign: 'left' }}>
                    <Text style={{ color: '#b0b0b0', marginTop: '10px', fontSize: '20px', display: 'block' }}>{item.description}</Text>
                  </div>
                  {/* <Text style={{ color: '#b0b0b0', marginTop: '-20px', fontSize: '20px', display: 'block' }}>Value</Text>
                  <div style={{ minHeight: '60px', textAlign: 'left' }}>
                    <Text style={{ color: '#b0b0b0', marginTop: '10px', fontSize: '15px', display: 'block' }}>{item.valueDescription}</Text>
                  </div> */}
                </Col>
              ))}
            </Row>
          </div >
        )
      }
    </>

  );
};

export default ReportsDashboard;
