import { useState, useEffect, useContext } from 'react';
import { useLocation } from 'react-router-dom';
import Navbar from '../Navbar';
import { Typography, Table, Button, Modal, Form, Input, Select, message, Switch, Tag } from 'antd';
import { NodeIndexOutlined } from '@ant-design/icons';
import { createNode, fetchSitesByOrganizationId, updateNode } from '../../Services';
import { AuthContext } from '../Login/AuthProvider';
import { Role } from '../../enums';

const ManageNodes = () => {
  const { auth } = useContext(AuthContext);
  const { Title } = Typography;
  const [form] = Form.useForm();

  // Use the location hook to retrieve passed data
  const location = useLocation();

  // Retrieve the organizationId passed from the previous screen
  const { organizationId } = location.state || { organizationId: null };

  // Initialize state for dataSource and loading
  const [dataSource, setDataSource] = useState<any[]>([]);
  const [loading, setLoading] = useState<boolean>(false);
  const [isNodeModalVisible, setIsNodeModalVisible] = useState<boolean>(false);
  const [mode, setMode] = useState<string | null>(null);
  const [eidtNodeId, setEditNodeId] = useState<number | null>(null);
  const [orgSites, setOrgSites] = useState<any[]>([]);
  const [isActiveCheck, setIsActiveCheck] = useState<boolean>(true);
  const [isStatusModalVisible, setIsStatusModalVisible] = useState<boolean>(false);

  const loadNodes = async () => {
    if (organizationId) {
      setLoading(true);  // Set loading to true before the API call
      const sitesData = await fetchSitesByOrganizationId(organizationId);  // Call API with organization ID

      if (sitesData) {
        // Format the data for the table

        const allSites = sitesData.map((record: any) => ({
          id: record.Site.id,
          name: record.Site.name
        }));



        const allNodes = sitesData.flatMap((record: any) => record.Site.Nodes.map((node: any) => node));

        const formattedData = allNodes.flatMap((node: any) => {

          const matchingSite = sitesData.find((item: any) => item.Site.Nodes.includes(node));
          if (matchingSite) {
            const siteData = { id: matchingSite.Site.id, name: matchingSite.Site.name }
            const organizationName = matchingSite.Organization.name;
            return { ...node, siteData: siteData, organization: organizationName }
          }

        });

        const MappedFormattedNodes = formattedData.map((node: any) => ({
          key: node.id,
          name: node.name,
          alias: node.alias !== null ? node.alias : "N/A",
          siteId: node.siteId,
          siteName: node.siteData.name,
          siteData: node.siteData,
          organization: node.organization,
          status: node.isActive,
        }));

        setOrgSites(allSites);
        setDataSource(MappedFormattedNodes);  // Set the formatted data to state
      }
      setLoading(false);  // Set loading to false after the API call
    }
  };

  useEffect(() => {
    loadNodes();
  }, [organizationId]);

  const handleNodeModal = (key: string, id?: number) => {

    setIsNodeModalVisible(true);
    setMode(key);
    if (key === "edit" && id !== undefined) {
      setEditNodeId(id);
      const newDataSource = [...dataSource];
      const index: number = newDataSource.findIndex((item: any) => item.key === id);
      if (index !== -1) {
        form.setFieldsValue({
          name: newDataSource[index].name,
          alias: newDataSource[index].alias,
          site: newDataSource[index].siteData.id,
        });
      }

    }
    else {
      form.resetFields();
    }
  };

  const handleStatusChange = async (checked: boolean, id: number) => {

    if (checked === false) {
      setIsActiveCheck(checked);
      setEditNodeId(id);
      setIsStatusModalVisible(true);
    }
    else {
      const updatedStatus = {
        isActive: checked,
      };
      const result = await updateNode(id, updatedStatus);
      if (result) {
        const { message: updatedNodeMessage } = result;
        message.success(updatedNodeMessage, 3);
        loadNodes();
      }
    }

  };

  const handleStatusUpdate = async () => {
    try {
      if (eidtNodeId) {
        const updatedStatus = {
          isActive: isActiveCheck,
        };

        const result = await updateNode(eidtNodeId, updatedStatus);

        if (result) {
          const { message: updatedNodeMessage } = result;
          message.success(updatedNodeMessage, 3)
          handleStatusCancel();
          loadNodes();
        }
      }

    } catch (e) {
      console.log(e);
    }
  };

  const handleStatusCancel = () => {
    setIsStatusModalVisible(false);
    setIsActiveCheck(true);
    setEditNodeId(null);
  }

  const handleSubmit = async () => {
    try {
      const values = await form.validateFields();

      if (mode === "add") {

        const newNodeData = {
          name: values.name,
          siteId: values.site,
          alias: values.alias === undefined || values.alias.trim() === '' ? null : values.alias,
          isActive: true,
        }

        const result = await createNode(newNodeData);
        if (result) {
          const { message: createdNodeMessage, status } = result;
          if (status === 409) {
            message.error(createdNodeMessage);
            return;
          }
          message.success(createdNodeMessage);
          handleCancel();
          loadNodes();
        }
      }
      else {
        if (eidtNodeId) {

          const updatedNode = {
            name: values.name,
            siteId: values.site,
            alias: values.alias === undefined || values.alias.trim() === '' || values.alias === 'N/A' ? null : values.alias,
          }
          const result = await updateNode(eidtNodeId, updatedNode);
          if (result) {
            const { message: updatedNodeMessage, status } = result;
            if (status === 409) {
              message.error(updatedNodeMessage);
              return;
            }
            message.success(updatedNodeMessage, 3)
            handleCancel();
            loadNodes();
          }

        }

      }


    } catch (e) {
      console.log(e);
    }
  };

  const handleCancel = async () => {
    setMode(null);
    setEditNodeId(null);
    form.resetFields();
    setIsNodeModalVisible(false);
  };

  const customColumns = [
    {
      title: 'NAME',
      dataIndex: 'name',
      key: 'name',
      sorter: (a: any, b: any) => a.name.localeCompare(b.name)
    },
    {
      title: 'ALIAS',
      dataIndex: 'alias',
      key: 'alias',
      sorter: (a: any, b: any) => a.alias.localeCompare(b.alias)
    },
    {
      title: 'SITE',
      dataIndex: 'siteName',
      key: 'siteName',
      sorter: (a: any, b: any) => a.siteName.localeCompare(b.siteName)
    },
    {
      title: 'ORGANIZATION',
      dataIndex: 'organization',
      key: 'organization',
      sorter: (a: any, b: any) => a.organization.localeCompare(b.organization)
    },
    {
      title: 'STATUS',
      dataIndex: 'status',
      key: 'status',
      width: 250,  // Set a fixed width for the status column
      render: (status: boolean, record: any) => (
        <>
          <div style={{ display: 'flex', gap: '10px', alignItems: 'center' }}>

            {(auth.role == Role.SUPER_ADMIN || auth.role == Role.ADMIN) ? ( // Replace this with your custom condition
              <Switch
                checked={status}  // Use 'checked' instead of 'value'
                onChange={(check: boolean) => handleStatusChange(check, record.key)}
              />
            ) : null}



            <Tag
              color={status ? 'green' : 'red'}
              style={{
                width: '80px',  // Set a fixed width for the tag
                textAlign: 'center',  // Center the text inside the tag
              }}
            >
              {status ? "Active" : "Inactive"}
            </Tag>
          </div>
        </>
      ),
    },
    ...((auth.role == Role.SUPER_ADMIN || auth.role == Role.ADMIN)
      ? [
        {
          title: 'ACTIONS',
          key: 'actions',
          render: (record: any) => (
            <Button
              type="default"
              onClick={() => handleNodeModal("edit", record.key)}
            >
              Edit
            </Button>
          ),
        },
      ]
      : [])







  ]


  return (
    <>
      <div className='h-[10vh]'>
        <Navbar page={'manageNodes'} />
      </div>
      <div className='h-[190vh] p-5'>
        <Title style={{ textAlign: 'center', marginBottom: 20, fontSize: 20 }}>
          ADMIN SETTINGS
        </Title>
        <div style={{ display: 'flex', alignItems: 'center', marginLeft: 40, marginBottom: 20 }}>
          <NodeIndexOutlined style={{ fontSize: 60, marginRight: 10, color: 'white' }} />
          <Title level={3} style={{ fontSize: 20, marginBottom: 0 }}>
            NODES
          </Title>
        </div>
        <Table
          style={{ paddingLeft: 40, paddingRight: 40 }}
          dataSource={dataSource} // Data from API response
          columns={customColumns}
          bordered={true}
          pagination={false}
          loading={loading}  // Show loading spinner while fetching data
          footer={() => (
            (auth.role === Role.ADMIN || auth.role === Role.SUPER_ADMIN) &&
            <Button
              type="dashed"
              onClick={() => handleNodeModal("add")}
            >
              + Add New Node
            </Button>
          )}
        />
      </div>

      <Modal
        title={mode === "add" ? "Add New Node" : "Edit Node"}
        visible={isNodeModalVisible}
        onCancel={handleCancel}
        onOk={handleSubmit}
        okText={mode === "add" ? 'Add' : 'Save'}
      >
        <Form form={form} layout="vertical">

          <Form.Item
            name="name"
            label="Node Name"
            rules={[{ required: true, message: 'Enter Node Name' }]}
          >
            <Input />
          </Form.Item>


          <Form.Item
            name="alias"
            label="Node Alias"
            rules={[{ required: false }]}
          >
            <Input />
          </Form.Item>

          <Form.Item
            name="site"
            label="Site"
            rules={[{ required: true, message: 'Select Node Site' }]}
          >
            <Select
              options={orgSites.map((site: any) => ({ label: site.name, value: site.id }))}
            />
          </Form.Item>


        </Form>

      </Modal>

      <Modal
        title={"Deactivate Node"}
        visible={isStatusModalVisible}
        onCancel={handleStatusCancel}
        cancelText={"No"}
        onOk={handleStatusUpdate}
        okText={"Yes"}
      >
        <br />
        Are you sure you want to Deactivate the Node?
      </Modal >
    </>
  )
}

export default ManageNodes