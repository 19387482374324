import { useRef, useEffect, useState } from 'react';
import * as d3 from 'd3';
import { Select } from 'antd';

const D3Waterfall = ({ powerData, waterfallXAxisData, waterfallYAxisData, minValue, maxValue, zoomTransform, waterFallYAxisPoints, setWaterFallYAxisRange }: any) => {
  // let seconds: number = 10;
  const canvasRef = useRef<HTMLCanvasElement | null>(null);
  const prevZoomTransform = useRef<d3.ZoomTransform | null>(null);  // To store the previous zoom state

  const [tooltip, setTooltip] = useState<{ x: number, y: number, value: number, frequency: number } | null>(null);

  const colors = [
    "#00008B", "#0504AA", "#0137FF", "#017EF5", "#00FA9F", "#00FAF3",
    "#01E651", "#59FA00", "#D2F500", "#EAD600", "#F4C204", "#FAA100",
    "#FF8800", "#f55600", "#F53200", "#FA0101",
  ];

  const generateColorScale = (colors: string[], minValue: number, maxValue: number) => {
    const numColors = colors.length;
    return d3.scaleLinear<string>()
      .domain(Array.from({ length: numColors }, (_, i) => minValue + (i * (maxValue - minValue) / (numColors - 1))))
      .range(colors)
      .interpolate(d3.interpolateRgb);
  };

  const colorScale = generateColorScale(colors, minValue, maxValue);

  useEffect(() => {
    if (!canvasRef.current) return;
    const canvas = canvasRef.current;
    const ctx = canvas.getContext('2d');
    if (!ctx) return;

    const width = canvas.width;
    const height = powerData.length * 30 + 100;
    const barHeight = 30;
    const margin = { top: 20, right: 30, bottom: 110, left: 30 };
    let zoomStatus = 0;

    // Check if the incoming zoom transform is the same as the previous one
    if (
      prevZoomTransform.current &&
      zoomTransform &&
      prevZoomTransform.current.k === zoomTransform.k &&
      prevZoomTransform.current.x === zoomTransform.x &&
      prevZoomTransform.current.y === zoomTransform.y
    ) {
      zoomStatus = 0;
    }
    else {
      // Update the previous zoom transform reference
      zoomStatus = 1;
      prevZoomTransform.current = zoomTransform || d3.zoomIdentity;
    }



    ctx.clearRect(0, 0, width, height);

    // Provide a fallback default for zoomTransform if it's undefined
    const appliedZoomTransform = zoomTransform || d3.zoomIdentity;

    if (zoomStatus === 1) {
      if (appliedZoomTransform.k >= 1.3) {
        appliedZoomTransform.x = appliedZoomTransform.x + 20
      }
      if (appliedZoomTransform.k > 1.5) {
        appliedZoomTransform.x = appliedZoomTransform.x + 5
      }
      if (appliedZoomTransform.k > 1.7) {
        appliedZoomTransform.x = appliedZoomTransform.x + 5
      }
      if (appliedZoomTransform.k > 2) {
        appliedZoomTransform.x = appliedZoomTransform.x + 10
      }
      if (appliedZoomTransform.k > 2.5) {
        appliedZoomTransform.x = appliedZoomTransform.x + 20
      }
      if (appliedZoomTransform.k >= 3) {
        appliedZoomTransform.x = appliedZoomTransform.x + 20
      }
      if (appliedZoomTransform.k > 3) {
        appliedZoomTransform.x = appliedZoomTransform.x + 20
      }
      if (appliedZoomTransform.k >= 4) {
        appliedZoomTransform.x = appliedZoomTransform.x + 20
      }


    }


    // Use zoomTransform to rescale X-axis
    const xScale = appliedZoomTransform.rescaleX(
      d3.scaleLinear()
        .domain([d3.min(waterfallXAxisData as number[])!, d3.max(waterfallXAxisData as number[])!])
        .range([margin.left, width - margin.right])
        .clamp(true)
    );

    const yScale = d3.scaleLinear()
      .domain([0, powerData.length])
      .range([0, height - margin.bottom]);

    // Draw Y-axis labels
    waterfallYAxisData.forEach((d: number, i: number) => {
      const y = yScale(i);
      ctx.fillStyle = 'white';  // Set the fill color to white
      ctx.fillText(`${d} sec`, margin.left - 20, y + margin.top + barHeight / 2);
    });

    ctx.save();
    ctx.translate(margin.left, margin.top);

    // Plot all points with the zoomed-in X-scale
    powerData.forEach((row: number[], i: number) => {
      row.forEach((power, j) => {
        const x = xScale(waterfallXAxisData[j]);
        const y = i * barHeight;

        // Prevent the bars from becoming too small by setting a minimum width
        const rectWidth = Math.max((width - margin.left - margin.right) / row.length / appliedZoomTransform.k, 1);

        ctx.fillStyle = colorScale(power);
        ctx.fillRect(x, y, rectWidth, barHeight); // Ensure width has a minimum size
      });
    });

    ctx.restore();

    // Draw the X-axis at the bottom of the graph
    const xAxisTicks = xScale.ticks(10);  // Adjust the number of ticks as needed

    ctx.beginPath();
    ctx.strokeStyle = 'white';
    ctx.lineWidth = 1;

    const tickOffsetX = 25; // Move ticks left/right by changing this value
    const tickOffsetY = 35;  // Move tick lines up/down by changing this value
    const labelOffsetY = 60;  // Adjust label positioning by changing this value

    xAxisTicks.forEach((tick: any) => {
      const x = xScale(tick) + tickOffsetX;  // Adjust tickOffsetX to move ticks left or right

      // Draw tick line
      ctx.moveTo(x, height - margin.bottom + tickOffsetY);  // Adjust tickOffsetY to move ticks up/down
      ctx.lineTo(x, height - margin.bottom + 6 + tickOffsetY);  // Small tick mark
      ctx.stroke();

      // Draw tick label
      const label = `${tick.toFixed(1)} MHz`;  // Define the label text
      const labelWidth = ctx.measureText(label).width;  // Measure label width for centering

      // Adjust the label's X position to center it on the tick line
      ctx.fillText(label, x - labelWidth / 2, height - margin.bottom + labelOffsetY);  // Move label up/down with labelOffsetY
    });

    // Tooltip logic
    canvas.addEventListener('mousemove', (event) => {
      const rect = canvas.getBoundingClientRect();
      const mouseX = event.clientX - rect.left;
      const mouseY = event.clientY - rect.top;

      let found = false;
      powerData.forEach((row: number[], i: number) => {
        row.forEach((power, j) => {
          const x = xScale(waterfallXAxisData[j]);
          const y = i * barHeight + margin.top;

          if (
            mouseX >= x &&
            mouseX <= x + Math.max((width - margin.left - margin.right) / row.length / appliedZoomTransform.k, 1) &&
            mouseY >= y &&
            mouseY <= y + barHeight
          ) {
            setTooltip({
              x: mouseX,
              y: mouseY,
              value: power,
              frequency: waterfallXAxisData[j],
            });
            found = true;
          }
        });
      });

      if (!found) {
        setTooltip(null);
      }
    });

    return () => {
      canvas.removeEventListener('mousemove', () => { });
    };
  }, [powerData, colorScale, waterfallXAxisData, waterfallYAxisData, zoomTransform]);

  return (
    <>
      <div className='flex flex-col items-center justify-center mr-14 relative'>
        <Select
          style={{ width: 200 }}
          allowClear={true}
          placeholder="Set Y-Axis"
          options={[5, 10, 15, 20].map((second: number) => ({ label: `${second} sec`, value: second }))}
          onChange={(e) => setWaterFallYAxisRange(e)}
          className="ml-auto"
        />
        <canvas
          ref={canvasRef}
          width={1220}
          height={
            waterFallYAxisPoints === 5 || waterFallYAxisPoints === 10 ? 370 :
              (waterFallYAxisPoints === 15 ? 500 : 650)
          }
        />
        {tooltip && (
          <div
            style={{
              position: 'absolute',
              left: tooltip.x + 15,
              top: tooltip.y - 25,
              background: 'rgba(0, 0, 0, 0.7)',
              color: 'white',
              padding: '5px',
              borderRadius: '3px',
              pointerEvents: 'none',
            }}
          >
            <div>Power: {tooltip?.value} dBm</div>
            <div>Frequency: {tooltip?.frequency?.toFixed(4)} MHz</div>
          </div>
        )}
      </div>
    </>
  );
};

export default D3Waterfall;
