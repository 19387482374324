import React from 'react';
import { Modal } from 'antd';
import NewReport from './NewReport';
import NewReportExisitngParams from './NewReportExisitngParams';


const ReportsModal = ({ isModalVisible, setIsModalVisible, selectedReportOption }: any) => {
  return (
    <div style={{ color: "white" }}>
      <Modal
        visible={isModalVisible}
        onCancel={() => setIsModalVisible(false)}
        footer={null}
        centered
        width="100vw"
        bodyStyle={{ height: '100vh', padding: 0 }}
        style={{ top: 0 }}
      >
        {selectedReportOption === "New" ? <NewReport setIsModalVisible={setIsModalVisible} /> : <></>}
        {selectedReportOption === "New with existing params" ? <NewReportExisitngParams setIsModalVisible={setIsModalVisible} /> : <></>}

      </Modal>
    </div>
  );
};

export default ReportsModal;
