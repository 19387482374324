import { useState, useEffect, useContext } from "react";
import Navbar from "../Navbar";
import {
  Typography,
  Table,
  Button,
  Space,
  Modal,
  Form,
  Input,
  Select,
  Switch,
  message,
  Tag,
} from "antd";
import { BankOutlined } from "@ant-design/icons";
import { useLocation, useNavigate } from "react-router-dom";
import {
  getOrganizations,
  createOrganization,
  updateOrganization,
} from "../../Services"; // Import service methods
import { AuthContext } from '../Login/AuthProvider';
// import { Role } from '../../enums';
import { Role } from "../../enums";
import { Utilities } from "../../Utilities";

const Organizations = () => {
  const { auth } = useContext(AuthContext);
  const { Title } = Typography;
  const location = useLocation();

  const { organizationId } = location.state || { organizationId: null };
  const utils = new Utilities();

  const [dataSource, setDataSource] = useState<any[]>([]); // State to store organization data
  const [isModalVisible, setIsModalVisible] = useState(false); // Modal visibility state for organization form
  const [userRole, setUserRole] = useState<string | null>(null);

  // const [isUserModalVisible, setIsUserModalVisible] = useState(false); // Modal visibility state for users modal
  // const [isSitesModalVisible, setIsSitesModalVisible] = useState(false);
  // const [isNodesModalVisible, setIsNodesModalVisible] = useState(false);
  const [isEditing, setIsEditing] = useState(false); // State to determine if we're editing
  const [selectedOrgId, setSelectedOrgId] = useState<number | null>(null);
  // const [selectedOrgName, setSelectedOrgName] = useState<string | null>(null); // Track the organization ID being edited
  // const [selectedUsers, setSelectedUsers] = useState<any[]>([]); // Store the users of the clicked organization
  const [form] = Form.useForm(); // Form instance to control the form
  const navigate = useNavigate();
  const [expandedColumn, setExpandedColumn] = useState<string | null>(null); // Track which column is expanded
  const [expandedRowKeys, setExpandedRowKeys] = useState<string[]>([]);
  const [isActiveCheck, setIsActiveCheck] = useState<boolean>(true);
  const [isStatusModalVisible, setIsStatusModalVisible] =
    useState<boolean>(false);

  const fetchOrganizations = async () => {
    const response = await getOrganizations();
    if (response) {
      // Transform the API response to match your table's data format
      const formattedData = response.map((org: any) => {
        // Extract all nodes from all sites
        const nodesArray = org.Sites.reduce((acc: any[], site: any) => {
          return [...acc, ...site.Nodes]; // Collect all nodes from each site
        }, []);

        return {
          key: org.id, // Use the organization's id as the key
          name: org.name,
          users: org.Users,
          Sites: org.Sites,
          activeUsers: org.Users.length, // Set activeUsers to the length of Users array
          sites: org.Sites.length, // Set sites to the length of Sites array
          nodes: org.Sites.reduce(
            (acc: number, site: any) => acc + site.Nodes.length,
            0
          ), // Sum the length of all Nodes arrays in Sites
          activeNodes: nodesArray, // Save the nodes array in the activeNodes parameter
          status: org.isActive, // Convert boolean to string
        };
      });
      setDataSource(formattedData); // Update the state with the formatted data
    }
  };

  useEffect(() => {
    fetchOrganizations();
  }, []);

  useEffect(() => {
    if (auth.token) {
      const decodedUserRole = utils.jwtDecode(auth.token)?.role;

      setUserRole(decodedUserRole);
      // loadUsers();
    }
  }, [auth.token, organizationId]);
  // Handle clicking column headers to expand or collapse all rows
  const handleColumnHeaderClick = (columnKey: string) => {
    if (expandedColumn === columnKey) {
      setExpandedRowKeys([]); // Collapse all rows
      setExpandedColumn(null); // Unset the expanded column
    } else {
      const allRowKeys = dataSource.map((record) => record.key); // Get all row keys
      setExpandedRowKeys(allRowKeys); // Expand all rows
      setExpandedColumn(columnKey); // Set the expanded column
    }
  };

  // Open modal for adding or editing organization
  const showModal = (isEdit: boolean, id?: number) => {
    setIsModalVisible(true);
    setIsEditing(isEdit);

    if (isEdit && id !== undefined) {
      // Prepopulate form with existing data if editing
      setSelectedOrgId(id); // Store the selected organization's ID
      const newDataSource = [...dataSource];
      const index: number = newDataSource.findIndex(
        (item: any) => item.key === id
      );
      if (index !== -1) {
        form.setFieldsValue({
          name: newDataSource[index].name,
        });
      }
    } else {
      form.resetFields(); // Clear form if adding new
    }
  };

  // const showUserModal = (users: any[]) => {
  //   setSelectedUsers(users); // Set the users of the selected organization
  //   setIsUserModalVisible(true); // Show the user modal
  // };

  // const showSitesModal = (organizationId: number, sites: any[]) => {
  //   setSelectedUsers(sites); // Set the sites of the selected organization
  //   setSelectedOrgId(organizationId); // Store the selected organization ID
  //   setIsSitesModalVisible(true); // Show the sites modal
  // };

  // const showNodesModal = (organizationId: number, nodes: any[]) => {
  //   setSelectedUsers(nodes); // Set the sites of the selected organization
  //   setSelectedOrgId(organizationId); // Store the selected organization ID
  //   setIsNodesModalVisible(true);
  // };
  // Handle modal close for organization form
  const handleCancel = () => {
    setIsModalVisible(false);
    form.resetFields(); // Reset form fields when modal is closed
    setSelectedOrgId(null); // Clear selected ID when closing the modal
    setIsEditing(false);
  };

  // Handle modal close for users modal
  // const handleUserModalCancel = () => {
  //   setIsUserModalVisible(false);
  //   setSelectedUsers([]); // Reset selected users when modal is closed
  // };

  // Handle modal close for sites modal
  // const handleSitesModalCancel = () => {
  //   setIsSitesModalVisible(false);
  //   setSelectedUsers([]); // Reset selected users when modal is closed
  // };

  // Handle modal close for nodes modal
  // const handleNodesModalCancel = () => {
  //   setIsNodesModalVisible(false);
  //   setSelectedUsers([]); // Reset selected users when modal is closed
  // };

  // Handle form submission for both adding and editing organizations
  const handleOk = async () => {
    const values = await form.validateFields();

    if (isEditing && selectedOrgId) {
      // Call updateOrganization when editing
      const updatedOrganizationData = {
        name: values.name,
      };

      const result = await updateOrganization(
        selectedOrgId,
        updatedOrganizationData
      );
      if (result) {
        const { message: updatedOrganizationMessage, status } = result;
        if (status === 409) {
          message.error(updatedOrganizationMessage);
          return;
        }
        message.success(updatedOrganizationMessage);
        handleCancel();
        fetchOrganizations();
      }
    } else {
      const newOrganizationData = {
        name: values.name,
        isActive: true,
      };
      // Call createOrganization when adding a new organization
      // const createdOrg = await createOrganization(newOrganizationData);
      const result = await createOrganization(newOrganizationData);
      if (result) {
        const { message: createdOrganizationMessage, status } = result;
        if (status === 409) {
          message.error(createdOrganizationMessage);
          return;
        }
        message.success(createdOrganizationMessage);
        handleCancel();
        fetchOrganizations();
      }
    }

    // Close the modal and reset the form
    setIsModalVisible(false);
    form.resetFields();
  };

  const handleStatusChange = async (checked: boolean, id: number) => {
    if (checked === false) {
      setIsActiveCheck(checked);
      setSelectedOrgId(id);
      setIsStatusModalVisible(true);
    } else {
      const updatedStatus = {
        isActive: checked,
      };
      const result = await updateOrganization(id, updatedStatus);
      if (result) {
        const { message: updatedOrganizationMessage } = result;
        message.success(updatedOrganizationMessage);
        fetchOrganizations();
      }
    }
  };

  const handleStatusUpdate = async () => {
    try {
      if (selectedOrgId) {
        const updatedStatus = {
          isActive: isActiveCheck,
        };
        const result = await updateOrganization(selectedOrgId, updatedStatus);
        if (result) {
          const { message: updatedOrganizationMessage } = result;
          message.success(updatedOrganizationMessage);
          handleStatusCancel();
          fetchOrganizations();
        }
      }
    } catch (e) {
      console.log(e);
    }
  };

  const handleStatusCancel = () => {
    setIsStatusModalVisible(false);
    setIsActiveCheck(true);
    setSelectedOrgId(null);
  };

  // Define columns for the table
  const columns = [
    {
      title: "NAME",
      dataIndex: "name",
      key: "name",
    },
    {
      title: (
        <span
          style={{
            color: "#1890ff",
            textDecoration: "underline",
            cursor: "pointer",
          }}
        >
          USERS
        </span>
      ),
      dataIndex: "activeUsers",
      key: "activeUsers",
      render: (_: any, record: any) => (
        <a
          onClick={() =>
            navigate("/manage-users", {
              state: {
                organizationId: record.key,
                organizationname: record.name,
              },
            })
          }
          style={{
            color: "#68BBE3",
            textDecoration: "underline",
            fontSize: 16,
          }} // Style applied
        >
          {record.activeUsers}
        </a>
      ),
      // Click header to toggle row expansion for this column
      onHeaderCell: () => ({
        onClick: () => handleColumnHeaderClick("activeUsers"),
      }),
    },
    {
      title: (
        <span
          style={{
            color: "#1890ff",
            textDecoration: "underline",
            cursor: "pointer",
          }}
        >
          SITES
        </span>
      ),
      dataIndex: "sites",
      key: "sites",
      render: (_: any, record: any) => (
        <a
          onClick={() =>
            navigate("/managesites", { state: { organizationId: record.key } })
          } // Pass organizationId and Sites
          style={{
            color: "#68BBE3",
            textDecoration: "underline",
            fontSize: 16,
          }}
        >
          {record.sites}
        </a>
      ),
      onHeaderCell: () => ({
        onClick: () => handleColumnHeaderClick("sites"),
      }),
    },
    {
      title: (
        <span
          style={{
            color: "#1890ff",
            textDecoration: "underline",
            cursor: "pointer",
          }}
        >
          NODES
        </span>
      ),
      dataIndex: "nodes",
      key: "nodes",
      render: (_: any, record: any) => (
        <a
          onClick={() =>
            navigate("/manage-nodes", { state: { organizationId: record.key } })
          }
          style={{
            color: "#68BBE3",
            textDecoration: "underline",
            fontSize: 16,
          }} // Style applied
        >
          {record.nodes}
        </a>
      ),
      // Click header to toggle row expansion for this column
      onHeaderCell: () => ({
        onClick: () => handleColumnHeaderClick("nodes"),
      }),
    },
    {
      title: "STATUS",
      dataIndex: "status",
      key: "status",
      width: 250, // Set a fixed width for the status column
      render: (status: boolean, record: any) => (
        <>
          <div style={{ display: 'flex', gap: '10px', alignItems: 'center' }}>

            {auth.role == Role.SUPER_ADMIN ? ( // Replace this with your custom condition
              <Switch
                checked={status}  // Use 'checked' instead of 'value'
                onChange={(check: boolean) => handleStatusChange(check, record.key)}
              />
            ) : null}




            <Tag
              color={status ? "green" : "red"}
              style={{
                width: "80px", // Set a fixed width for the tag
                textAlign: "center", // Center the text inside the tag
              }}
            >
              {status ? "Active" : "Inactive"}
            </Tag>
          </div>
        </>
      ),
    },

    ...(auth.role == Role.SUPER_ADMIN
      ? [
        {
          title: 'ACTIONS', // Updated column to include both Edit and Delete buttons
          key: 'actions',
          render: (record: any) => (
            <Space size="middle">
              <Button type="default" onClick={() => showModal(true, record.key)}>
                Edit
              </Button>
            </Space>
          ),
        },
      ]
      : [])


  ];

  return (
    <>
      <div className="h-[10vh]">
        <Navbar page={"organizations"} />
      </div>
      <div className="h-[100vh] p-5">
        <Title
          style={{
            textAlign: "center",
            marginBottom: 20,
            
            fontSize: 20,
          }}
        >
          ADMIN SETTINGS
        </Title>
        <div
          style={{
            display: "flex",
            alignItems: "center",
            marginLeft: 40,
            marginBottom: 20,
          }}
        >
          <BankOutlined
            style={{ fontSize: 60, marginRight: 10, color: "white" }}
          />
          <Title level={3} style={{ fontSize: 20, marginBottom: 0 }}>
            ORGANIZATION
          </Title>
        </div>
        <Table
          style={{ paddingLeft: 40, paddingRight: 40 }}
          dataSource={dataSource} // Data comes from API response
          columns={columns}
          pagination={false}
          expandedRowKeys={expandedRowKeys} // Control expanded rows
          onExpand={() => { }} // Disable default expand behavior
          // rowClassName={() => (expandedColumn ? 'expanded-row' : '')} // Add CSS class to expanded rows
          expandIconColumnIndex={-1} // Remove expand icon column
          expandedRowRender={(record: any) => {
            // Render content based on the expanded column
            switch (expandedColumn) {
              case "activeUsers":
                return (
                  <>
                    {record.users.length === 0 ? (
                      <p>Users do not exist</p>
                    ) : (
                      <>
                        <div>
                          <h3 style={{ textDecoration: "underline" }}>
                            Active Users
                          </h3>
                          {record.users.filter((user: any) => user.isActive)
                            .length === 0 ? (
                            <p>No active users</p>
                          ) : (
                            <ul>
                              {record.users
                                .filter((user: any) => user.isActive)
                                .map((user: any, index: number) => (
                                  <li key={index}>
                                    {index + 1}- {user.userName}
                                  </li>
                                ))}
                            </ul>
                          )}
                        </div>

                        <div>
                          <h3 style={{ textDecoration: "underline" }}>
                            Inactive Users
                          </h3>
                          {record.users.filter((user: any) => !user.isActive)
                            .length === 0 ? (
                            <p>No inactive users</p>
                          ) : (
                            <ul>
                              {record.users
                                .filter((user: any) => !user.isActive)
                                .map((user: any, index: number) => (
                                  <li key={index}>
                                    {index + 1}- {user.userName}
                                  </li>
                                ))}
                            </ul>
                          )}
                        </div>
                      </>
                    )}
                  </>
                );

              case "sites":
                return (
                  <ul>
                    {record.Sites.length === 0 ? (
                      <p>Sites do not exist</p>
                    ) : (
                      record.Sites.map((site: any, index: number) => (
                        <li key={index}>
                          {index + 1}- {site.name}
                        </li>
                      ))
                    )}
                  </ul>
                );

              case "nodes":
                return (
                  <ul>
                    {record.activeNodes.length === 0 ? (
                      <p>Nodes do not exist</p>
                    ) : (
                      record.activeNodes.map((node: any, index: number) => (
                        <li key={index}>
                          {index + 1}- {node.name}
                        </li>
                      ))
                    )}
                  </ul>
                );
              default:
                return null;
            }
          }}
          showHeader={true}
          bordered={true}
          footer={() => (
            auth.role == Role.SUPER_ADMIN &&
            <Button type="dashed" onClick={() => showModal(false)}>
              + Add New Organization
            </Button>
          )}
        />
      </div>

      {/* Modal for adding or editing an organization */}
      <Modal
        title={isEditing ? "Edit Organization" : "Add New Organization"}
        visible={isModalVisible}
        onOk={handleOk}
        onCancel={handleCancel}
        okText={isEditing ? "Save" : "Add"}
        cancelText="Cancel"
      >
        <Form form={form} layout="vertical">
          <Form.Item
            name="name"
            label="Organization Name"
            rules={[
              {
                required: true,
                message: "Please input the organization name!",
              },
            ]}
          >
            <Input placeholder="Enter organization name" />
          </Form.Item>
        </Form>
      </Modal>

      {/* <Modal
        title="Users"
        visible={isUserModalVisible}
        onCancel={handleUserModalCancel}
        footer={[
          <Button key="edit" type="primary" onClick={() => navigate('/manage-users')}>
            Manage Users
          </Button>,
        ]} // The footer array now includes the Edit button
      >
        <ul>
          {selectedUsers.length === 0 ? <p>No Active Users</p> :
            selectedUsers
              .sort((a, b) => a.id - b.id) // Sort by id in ascending order
              .map((user) => (
                <li key={user.id}>
                  {user.id} - {user.userName}
                </li>
              ))}
        </ul>
      </Modal>



      {/* <Modal
        title="Sites"
        visible={isSitesModalVisible}
        onCancel={handleSitesModalCancel}
        footer={[
          <Button
            key="edit"
            type="primary"
            onClick={() => navigate('/managesites', { state: { organizationId: selectedOrgId } })} // Pass organizationId when navigating
          >
            Manage Sites
          </Button>,
        ]}
      >
        <ul>
          {selectedUsers.length === 0 ? <p>Sites do not exist</p> :
            selectedUsers
              .sort((a: any, b: any) => a.id - b.id) // Sort the array by id in ascending order
              .map((site, index) => (
                <li key={index}>
                  {index + 1} - {site.name}
                </li>
              ))}
        </ul>
      </Modal> */}

      {/* <Modal
        title="Nodes"
        visible={isNodesModalVisible}
        onCancel={handleNodesModalCancel}
        footer={[
          <Button
            key="edit"
            type="primary"
            onClick={() => navigate('/manage-nodes', { state: { organizationId: selectedOrgId } })}
          >
            Manage Nodes
          </Button>,
        ]} // The footer array now includes the Edit button
      >
        <ul>
          {selectedUsers.length === 0 ? <p>Nodes do not exist</p> :
            selectedUsers
              .sort((a: any, b: any) => a.id - b.id) // Sort the array by id in ascending order
              .map((node, index) => (
                <li key={index}>
                  {index + 1} - {node.name}
                </li>
              ))}
        </ul>
      </Modal> */}

      <Modal
        title={"Deactivate Organization"}
        visible={isStatusModalVisible}
        onCancel={handleStatusCancel}
        cancelText={"No"}
        onOk={handleStatusUpdate}
        okText={"Yes"}
      >
        <br />
        Are you sure you want to Deactivate the Organization?
      </Modal>
    </>
  );
};

export default Organizations;
