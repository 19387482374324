import { useEffect, useState, useRef } from 'react';
import { Select, Tooltip } from 'antd';
import Navbar from './Navbar';
import { Button, message } from 'antd';
import { CaretRightOutlined, FastBackwardOutlined, FastForwardOutlined, PauseOutlined } from "@ant-design/icons";
import { Utilities } from '../Utilities';
import * as d3 from 'd3';
import D3Waterfall from './Graphs/D3Waterfall';
import PsdRangeSettings from './Settings/PsdRangeSettings';
import { getPsdData } from '../Services';
import D3Spectral from './Graphs/D3Spectral';
import { ManualSpinnerLoader } from '../ManualSpinnerLoader';

const Displays = () => {
  const utils = new Utilities();
  const [spectralWaterfallData, setSpectralWaterfallData] = useState<any>({ psd: [], start_times: [] });
  const [isPlaying, setIsPlaying] = useState(false);
  const [loading, setLoading] = useState(true);
  const intervalIdRef = useRef<any>(null);
  const storageData: any = localStorage.getItem("StorageData");
  const parsedStorageData = JSON.parse(storageData);
  const [powerData, setPowerData] = useState<number[][]>([]); // Define powerData as an array of number arrays
  const [currentIndex, setCurrentIndex] = useState(0);
  const [minValue, setMinValue] = useState<number>(0);
  const [maxValue, setMaxValue] = useState<number>(0);
  const [spectralState, setSpectralState] = useState({ zoomTransform: null });
  const [waterFallYAxisPoints, setWaterFallYAxisPoints] = useState<number>(10);


  const setMinAndMaxValues = (minValue: number, maxValue: number) => {
    setMinValue(minValue);
    setMaxValue(maxValue);
  };

  const setWaterFallYAxisRange = (value: number | undefined | null) => {
    setWaterFallYAxisPoints(value === undefined || value === null ? 10 : value);
    setCurrentIndex(0);
  };

  useEffect(() => {
    fetchPsdData();
  }, [waterFallYAxisPoints]);


  useEffect(() => {
    if (isPlaying) {
      intervalIdRef.current = window.setInterval(() => {
        setCurrentIndex((prevIndex) => {
          if (prevIndex < spectralWaterfallData.psd.length - 1) {
            const newIndex = prevIndex + 1;
            if (spectralWaterfallData.psd[newIndex]) {

              setPowerData((prevPowerData) => {
                const newPowerData = [...prevPowerData];
                newPowerData.unshift(spectralWaterfallData.psd[newIndex]); // Add new data at the start
                newPowerData.pop(); // Remove the last element to maintain the length
                return newPowerData;
              });
            }
            return newIndex;
          } else {
            setIsPlaying(false);
            message.info('End of data.');
            clearInterval(intervalIdRef.current);
            return prevIndex;
          }
        });
      }, 1000);
    } else {
      if (intervalIdRef.current) {
        clearInterval(intervalIdRef.current);
      }
    }

    return () => {
      if (intervalIdRef.current) {
        clearInterval(intervalIdRef.current);
      }
    };
  }, [isPlaying, currentIndex, spectralWaterfallData.psd.length]);

  const fetchPsdData = async () => {
    setLoading(true);
    try {
      const startTime = parsedStorageData.startTime;
      const oneMinAfterStartTime = utils.calculateOneMinuteDelayTime(startTime);
      const endTime = parsedStorageData.endTime;

      // Fetch the first 1 minute of data
      const firstBatchData = await getPsdData({
        count: parsedStorageData.count,
        start_time: startTime,
        end_time: oneMinAfterStartTime,
        mask_start_time: startTime,
        mask_end_time: oneMinAfterStartTime,
        threshold: parsedStorageData.threshold,
        job_recid: parsedStorageData.jobRecId,
        operation: parsedStorageData.operations,
      });

      // Handle the first batch of data
      if (firstBatchData.psd.length === 0 || firstBatchData.start_times.length === 0) {
        message.error(`No PSD data found on RecID: ${parsedStorageData.jobRecId}`);
      }

      setSpectralWaterfallData(firstBatchData || { psd: [], start_times: [] });



      const firstMinInitialData: any = firstBatchData.psd[0] || [];
      setPowerData([firstMinInitialData, ...Array.from({ length: waterFallYAxisPoints - 1 }, () => [])]);

      const firstMinAllValues: number[] = firstBatchData.psd.flat();

      const firstMin: number = d3.min(firstMinAllValues) ?? -Infinity;
      const firstMax: number = d3.max(firstMinAllValues) ?? Infinity;
      setMinValue(firstMin);
      setMaxValue(firstMax);


      setLoading(false);

      // Fetch the remaining data(after 1 minute)
      const secondBatchData = await getPsdData({
        count: parsedStorageData.count,
        start_time: oneMinAfterStartTime,
        end_time: endTime,
        mask_start_time: oneMinAfterStartTime,
        mask_end_time: endTime,
        threshold: parsedStorageData.threshold,
        job_recid: parsedStorageData.jobRecId,
        operation: parsedStorageData.operations,
      });

      if (secondBatchData.psd.length === 0 || secondBatchData.start_times.length === 0) {
        message.error(`No remaining PSD data found after 1 minute.`);
      }



      const totalPsdData = {
        psd: [...firstBatchData.psd, ...secondBatchData.psd], // Combine the psd arrays
        start_times: [...firstBatchData.start_times, ...secondBatchData.start_times] // Combine the start_times arrays
      }

      setSpectralWaterfallData(totalPsdData);

    } catch (error) {
      console.error('Failed to fetch data:', error);
      message.error('Failed to fetch data.');
      setLoading(false);
    }
  };



  const handlePlayPause = () => {
    setIsPlaying(!isPlaying);
  };

  const handleFastForward = () => {
    if (currentIndex < spectralWaterfallData.psd.length - 1) {
      setCurrentIndex((prevIndex) => {
        const newIndex = prevIndex + 1;
        if (spectralWaterfallData.psd[newIndex]) {
          setPowerData((prevPowerData) => {
            const newPowerData = [...prevPowerData];
            newPowerData.unshift(spectralWaterfallData.psd[newIndex]); // Add new data at the start
            newPowerData.pop(); // Remove the last element to maintain the length
            return newPowerData;
          });
        }
        return newIndex;
      });
    } else {
      message.info('End of data.');
    }
  };

  const handleRewind = () => {
    if (currentIndex > 0) {
      setCurrentIndex((prevIndex) => {
        const newIndex = prevIndex - 1;
        if (spectralWaterfallData.psd[newIndex]) {
          setPowerData((prevPowerData) => {
            const newPowerData = [...prevPowerData];
            if (newPowerData.every(key => key.length > 0 && prevIndex > waterFallYAxisPoints - 1)) {
              newPowerData.push(spectralWaterfallData.psd[prevIndex - waterFallYAxisPoints]);
            } else {
              newPowerData.push([]);
            }
            newPowerData.shift(); // Remove the first element to maintain the length
            return newPowerData;
          });
        }
        return newIndex;
      });
    } else {
      message.info('Already at the beginning.');
    }
  };

  const handleTime = (value: string) => {
    const newIndex = value ? spectralYAxisData.findIndex((time: string) => time === value) : 0;
    const difference = newIndex - currentIndex;
    setCurrentIndex(newIndex);

    setPowerData((prevPowerData) => {
      const newPowerData = [...prevPowerData];
      if (difference > 0) {
        for (let i = 0; i < difference; i++) {
          newPowerData.unshift(spectralWaterfallData.psd[currentIndex + i + 1]);
          newPowerData.pop();
        }
      } else if (difference < 0) {
        for (let i = 0; i < -difference; i++) {
          if (newPowerData.every(key => key.length > 0 && currentIndex - i > waterFallYAxisPoints - 1)) {
            newPowerData.push(spectralWaterfallData.psd[currentIndex - i - waterFallYAxisPoints]);
          } else {
            newPowerData.push([]);
          }
          newPowerData.shift();
        }
      }
      return newPowerData;
    });
  };

  // Handler to update state from D3Spectral
  const handleSpectralStateChange = (newState: any) => {
    setSpectralState(newState);
  };

  const power: number[] = spectralWaterfallData.psd[currentIndex] || [];
  const spectralYAxisData: string[] = spectralWaterfallData.start_times || [];

  const frequencyArray: number[] = utils.calculateFreq(power.length, parsedStorageData?.frequency, parsedStorageData?.sampleRate)
  const waterfallYAxisData = Array.from({ length: waterFallYAxisPoints + 1 }, (_, i) => i);

  return (
    <>
      <div className='h-[10vh]'>
        <Navbar page="displays" />
      </div>
      {loading ? <div className="w-screen h-screen bg-black flex justify-center items-center">
        <ManualSpinnerLoader componentName={"Displays"} />
      </div> :
        <div className="main-h-screen bg-black flex">
          <div className="flex flex-col w-full p-5 space-y-5">

            <Select
              style={{ width: '100%' }}
              allowClear={true}
              placeholder="Select a start time"
              onChange={handleTime}
              options={spectralYAxisData.map((time: string, index: number) => ({ label: `${index + 1}- ${time}`, value: time }))}
            />

            <PsdRangeSettings
              minValue={minValue}
              maxValue={maxValue}
              setMinAndMaxValues={setMinAndMaxValues}
            />

            <div className="bg-gray-800 text-white p-3 h-full rounded-lg">
              <D3Spectral
                power={power}
                frequencies={frequencyArray}
                times={spectralYAxisData}
                index={currentIndex}
                minValue={minValue}
                maxValue={maxValue}
                onStateChange={handleSpectralStateChange}
              />
            </div>

            <div className="bg-gray-800 text-white p-3 h-full rounded-lg" style={{ textAlign: 'center' }}>
              <Tooltip title="Rewind">
                <Button icon={<FastBackwardOutlined />} onClick={handleRewind} style={{ width: 100 }} />
              </Tooltip>
              <Tooltip title={isPlaying ? "Pause" : "Play"}>
                <Button icon={isPlaying ? <PauseOutlined /> : <CaretRightOutlined />} onClick={handlePlayPause} style={{ width: 100 }} />
              </Tooltip>
              <Tooltip title="Fast Forward">
                <Button icon={<FastForwardOutlined />} onClick={handleFastForward} style={{ width: 100 }} />
              </Tooltip>
            </div>

            <div className="bg-gray-800 text-white p-3 h-full rounded-lg">
              <D3Waterfall
                powerData={powerData}
                waterfallXAxisData={frequencyArray}
                waterfallYAxisData={waterfallYAxisData}
                minValue={minValue}
                maxValue={maxValue}
                zoomTransform={spectralState.zoomTransform}
                waterFallYAxisPoints={waterFallYAxisPoints}
                setWaterFallYAxisRange={setWaterFallYAxisRange}
              />
            </div>

          </div>
        </div>
      }

    </>
  );
};

export default Displays;
