import React, { useEffect, useRef, useState } from "react";
import * as d3 from "d3";

const ScatterUtilization = ({ occupancyFilter, checkFCC }: any) => {
  const data = [
    {
      power: -110,
      occupancy: 10,
      color: "#FF5733",
      description: {
        centerFrequency: "130 MHz",
        bandWidth: "12.5MHz",
        license: "WHV6",
        desc: "FedEx - Flight Control",
      },
    },
    {
      power: -105,
      occupancy: 20,
      color: "#33FF57",
      description: {
        centerFrequency: "135 MHz",
        bandWidth: "15MHz",
        license: "HVK3",
        desc: "Air Traffic Control",
      },
    },
    {
      power: -100,
      occupancy: 40,
      color: "#3357FF",
      description: {
        centerFrequency: "140 MHz",
        bandWidth: "10MHz",
        license: "JVG7",
        desc: "Weather Monitoring",
      },
    },
    {
      power: -95,
      occupancy: 25,
      color: "#FF33A5",
      description: {
        centerFrequency: "145 MHz",
        bandWidth: "20MHz",
        license: "LVK2",
        desc: "Maritime Communication",
      },
    },
    {
      power: -90,
      occupancy: 15,
      color: "#A533FF",
      description: {
        centerFrequency: "150 MHz",
        bandWidth: "5MHz",
        license: "NWV5",
        desc: "Emergency Services",
      },
    },
    {
      power: -85,
      occupancy: 55,
      color: "#FFC133",
      description: {
        centerFrequency: "155 MHz",
        bandWidth: "12.5MHz",
        license: "PXV9",
        desc: "Military Operations",
      },
    },
    {
      power: -80,
      occupancy: 60,
      color: "#33FFC1",
      description: {
        centerFrequency: "160 MHz",
        bandWidth: "18MHz",
        license: "QVH1",
        desc: "Public Broadcasting",
      },
    },
    {
      power: -75,
      occupancy: 35,
      color: "#FF5733",
      description: {
        centerFrequency: "165 MHz",
        bandWidth: "7.5MHz",
        license: "RVW4",
        desc: "Railway Communication",
      },
    },
    {
      power: -70,
      occupancy: 80,
      color: "#33A5FF",
      description: {
        centerFrequency: "170 MHz",
        bandWidth: "25MHz",
        license: "SVL3",
        desc: "Satellite Uplink",
      },
    },
    {
      power: -65,
      occupancy: 90,
      color: "#FF33C1",
      description: {
        centerFrequency: "175 MHz",
        bandWidth: "30MHz",
        license: "TXW8",
        desc: "Space Communication",
      },
    },
  ];

  const tooltipRef = useRef<any>();
  const svgRef = useRef<SVGSVGElement | null>(null);

  useEffect(() => {
    const tooltip = tooltipRef.current;

    const renderChart = () => {
      if (!svgRef.current) return;

      const width = parseInt(d3.select("#svg-container").style("width"), 10);
      const height = 500;
      const margin = { top: 20, right: 30, bottom: 50, left: 60 };

      d3.select(svgRef.current).selectAll("*").remove();

      const svg = d3
        .select(svgRef.current)
        .attr("width", width)
        .attr("height", height)
        .style("background-color", "#2c2c2c")
        .style("color", "#fff");

      const xScale = d3
        .scaleLinear()
        .domain([-120, 0])
        .range([margin.left, width - margin.right]);

      const yScale = d3
        .scaleLinear()
        .domain([0, 100])
        .range([height - margin.bottom, margin.top]);

      // X Axis with 'dBm' labels
      svg
        .append("g")
        .attr("transform", `translate(0,${height - margin.bottom})`)
        .style("color", "#9b9a9a")
        .call(
          d3
            .axisBottom(xScale)
            .ticks(10)
            .tickSize(-height + margin.top + margin.bottom)
            .tickFormat((d) => `${d} dBm`)
        )
        .selectAll("line")
        .style("stroke-dasharray", "8, 8");

      // Y Axis with '%' labels
      svg
        .append("g")
        .attr("transform", `translate(${margin.left},0)`)
        .style("color", "#9b9a9a")
        .call(
          d3
            .axisLeft(yScale)
            .ticks(10)
            .tickSize(-width + margin.left + margin.right)
            .tickFormat((d) => `${d}%`)
        )
        .selectAll("line")
        .style("stroke-dasharray", "8, 8");

      // X Axis Label
      svg
        .append("text")
        .attr("x", width / 2)
        .attr("y", height - 10)
        .attr("text-anchor", "middle")
        .style("fill", "#fff")
        .text("Power");

      // Y Axis Label
      svg
        .append("text")
        .attr("transform", "rotate(-90)")
        .attr("x", -height / 2)
        .attr("y", 20)
        .attr("text-anchor", "middle")
        .style("fill", "#fff")
        .text("Occupancy");

      // Plot the circles with conditional color based on the filter
      svg
        .selectAll("circle")
        .data(data)
        .enter()
        .append("circle")
        .attr("cx", (d) => xScale(d.power))
        .attr("cy", (d) => yScale(d.occupancy))
        .attr("r", 12)
        .attr(
          "fill",
          (d) =>
            occupancyFilter !== "all" && d.occupancy > parseInt(occupancyFilter)
              ? d.color
              : d.color + "4D" //logic to add the opacity at 50% for the downgraded points
        )
        .on("click", (event, d) => {
          if (tooltip) {
            const isVisible = tooltip.style.display === "block";
            if (isVisible) {
              tooltip.style.display = "none";
            } else {
              if (checkFCC) {
                tooltip.style.display = "block";
                tooltip.style.left = `${event.pageX + 10}px`;
                tooltip.style.top = `${event.pageY - 28}px`;
                tooltip.innerHTML = `
                  <strong>Center Frequency:</strong> ${d.description.centerFrequency} <br/>
                  <strong>Bandwidth:</strong> ${d.description.bandWidth} <br/>
                  <strong>Power:</strong> ${d.power} <br/>
                  <strong>Occupancy:</strong> ${d.occupancy}% <br/>
                  <strong>License:</strong> ${d.description.license} <br/>
                  <strong>Description:</strong> ${d.description.desc}
                `;
              } else {
                tooltip.style.display = "block";
                tooltip.style.left = `${event.pageX + 10}px`;
                tooltip.style.top = `${event.pageY - 28}px`;
                tooltip.innerHTML = `
                <strong>Center Frequency:</strong> ${d.description.centerFrequency} <br/>
                <strong>Bandwidth:</strong> ${d.description.bandWidth} <br/>
                <strong>Power:</strong> ${d.power} <br/>
                <strong>Occupancy:</strong> ${d.occupancy}% <br/>
                <strong>License:</strong> ${d.description.license}
              `;
              }
            }
          }
        });
    };

    renderChart();
    window.addEventListener("resize", renderChart);

    return () => {
      window.removeEventListener("resize", renderChart);
    };
  }, [data, occupancyFilter]);
  return (
    <div id="svg-container">
      {/* <select
        value={filter}
        onChange={(e) => setFilter(e.target.value)}
        style={{ marginBottom: "10px" }}
      >
        <option value="all">All Data</option>
        <option value="greaterThan30">Occupancy > 30%</option>
      </select> */}
      <div
        id="tooltip"
        ref={tooltipRef}
        className="text-sm font-medium"
        style={{
          position: "absolute",
          display: "none",
          pointerEvents: "none",
          backgroundColor: "#b7b7b7",
          color: "#232323",
          padding: "8px",
          borderRadius: "4px",
          fontSize: "12px",
        }}
      >
        dfdfdf
      </div>
      <svg ref={svgRef}></svg>
    </div>
  );
};

export default ScatterUtilization;
