import { Modal, Typography } from "antd";
import React from "react";

const ExportModal = ({
  isExportModalVisible,
  setIsExportModalVisible,
  type = "CSV",
  center,
}: any) => {
  const { Title } = Typography;

  const handleExport = () => {
    setIsExportModalVisible(false);
  };

  const handleCancel = () => {
    setIsExportModalVisible(false);
  };

  return (
    <Modal
      title={`Export as ${type}?`}
      open={isExportModalVisible}
      onOk={handleExport}
      onCancel={handleCancel}
      width={400}
      okText="Confirm"
      cancelText="Cancel"
      centered
    >
      <br />
      <br />
    </Modal>
  );
};

export default ExportModal;
