import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import Occupancy from '../Graphs/Occupancy';
import OccupancyDetail from '../Graphs/OccupanyDetail';
import { Select, Tabs, Spin, message } from 'antd';
import ChannelPeakPower from '../Graphs/ChannelPeakPower';
import Navbar from '../Navbar';
import ChannelAveragePower from '../Graphs/ChannelAveragePower';
import { getChopData } from '../../Services';
import ReportsModal from './Modal/ReportsModal';
import { Utilities } from '../../Utilities';
import { ManualSpinnerLoader } from '../../ManualSpinnerLoader';


const Reports: React.FC = () => {
  const location = useLocation();
  const { TabPane } = Tabs;
  const [selectedReport, setSelectedReport] = useState<string | null>(null);
  const [chopData, setChopData] = useState<any[]>([]);
  const [tabs, setTabs] = useState<any[]>([]);
  const [activeKey, setActiveKey] = useState<string | undefined>('');
  const [isModalVisible, setIsModalVisible] = useState<boolean>(false);
  const [selectedReportOption, setSelectedReportOption] = useState<string | null>(null);
  const [reportDetails, setReportDetails] = useState<any>(null);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const storageData: any = localStorage.getItem('StorageData');
  const parsedStorageData = JSON.parse(storageData);
  const initialReportKeyFromLocalStorage: any = localStorage.getItem('InitialReportKey');
  const parsedInitialReportKey: string = JSON.parse(initialReportKeyFromLocalStorage);
  const addReportOptions = ['New', 'New with existing params'];
  const utils = new Utilities();

  useEffect(() => {
    initialFetchedChopData();
  }, []);

  const initialFetchedChopData = async () => {
    setIsLoading(true);
    try {
      const data: any = await utils.loadChopDataFromIndexedDB();

      // const fetchedData = await getChopData(
      //   parsedStorageData.jobRecId,
      //   parsedStorageData.startTime,
      //   parsedStorageData.endTime
      // );

      // setChopData(fetchedData ?? []);
      // Set default tab and report details after fetching data


      const indexDbChopData = data.fetchedData;
      setTabs([
        {
          key: parsedInitialReportKey, // 'Channel Average Power' tab
          title: parsedInitialReportKey === "3" ? "Channel Average Power" : "Occupancy",
          content: parsedInitialReportKey === "3" ?
            (<ChannelAveragePower chopData={indexDbChopData} />)
            :
            (
              <>
                <Occupancy chopData={indexDbChopData} />
                <OccupancyDetail chopData={indexDbChopData} />
              </>
            ),
        },
      ]);
      setReportDetails({
        recid: parsedStorageData.jobRecId,
        nodeName: parsedStorageData.nodename,
        startTime: parsedStorageData.startTime,
        endTime: parsedStorageData.endTime,
      });







    } catch (e) {
      console.error('Error fetching data:', e);
      message.error('Failed to fetch data');
      // setIsLoading(true);
    }
    finally {
      setIsLoading(false); // Stop loading once data is fetched and saved
    }
  };

  useEffect(() => {
    if (location.state) {
      const { reportType, ...details } = location.state;
      setReportDetails(details);
      handleReportChange(reportType, details);
    }
  }, [location.state]);

  const onReportChangeChopData = async (details: any) => {
    setIsLoading(true);
    try {
      const fetchedData = await getChopData(

        details.recid,
        selectedReportOption === "New" ? details.startTime : details.startTime,
        selectedReportOption === "New" ? details.endTime : details.endTime
      );

      setChopData(fetchedData ?? []);
    } catch (e) {
      console.error('Error fetching data:', e);
    } finally {
      setIsLoading(false);
    }
  };

  const handleReportOption = (value: string) => {
    if (value !== undefined) {
      setSelectedReportOption(value);
      setIsModalVisible(true);
    }
  };

  const handleReportChange = (value: string, details?: any) => {
    if (!value) {
      return;
    }

    const report = reports.find((r) => r.title.toLowerCase().replace(/\s/g, '') === value.toLowerCase().replace(/\s/g, ''));
    if (!report) return;

    const recidKey = `${report.key}_${reportDetails.recid}`; // Unique key for each report type and recid

    // Check if the tab already exists based on the unique recidKey
    const existingTab = tabs.find((tab) => tab.key === recidKey);
    if (!existingTab) {
      // Add new tab if it doesn't exist
      setTabs([...tabs, { ...report, key: recidKey, details: details || reportDetails }]);
      setActiveKey(recidKey);
    } else {
      // Set the active tab to the existing one
      setActiveKey(existingTab.key);
    }

    setSelectedReport(value);
    setIsLoading(true); // Set loading true when report changes

    if (details) {
      onReportChangeChopData(details);
    } else if (reportDetails) {
      onReportChangeChopData(reportDetails);
    }
  };

  const removeTab = (targetKey: string) => {
    let newActiveKey = activeKey;
    let lastIndex = -1;
    tabs.forEach((tab, i) => {
      if (tab.key === targetKey) {
        lastIndex = i - 1;
      }
    });
    const newTabs = tabs.filter((tab) => tab.key !== targetKey);
    if (newTabs.length && newActiveKey === targetKey) {
      if (lastIndex >= 0) {
        newActiveKey = newTabs[lastIndex].key;
      } else {
        newActiveKey = newTabs[0].key;
      }
    } else {
      newActiveKey = undefined; // Set to undefined when no tabs are left
    }
    setTabs(newTabs);
    setActiveKey(newActiveKey);
  };


  // This function adds a 4th tab if it doesn't match any of the predefined reports
  const handleNewReportTab = (newTab: any) => {
    const reportExists = tabs.some(tab => tab.key === newTab.key);
    if (!reportExists) {
      setTabs([...tabs, newTab]);
      setActiveKey(newTab.key);
    } else {
      setActiveKey(newTab.key);
    }
  };

  const reports = [
    {
      key: '1',
      title: 'Occupancy',
      content: (
        <>
          {isLoading ? (
            <div className="flex justify-center items-center h-full">
              <Spin size="large" />
            </div>
          ) : chopData.length > 0 ? (
            <>
              <Occupancy chopData={chopData} details={reportDetails} />
              <OccupancyDetail chopData={chopData} details={reportDetails} />
            </>
          ) : (
            <div>No Data Available</div>
          )}
        </>
      ),
    },
    {
      key: '2',
      title: 'Channel Peak Power',
      content: (
        <>
          {isLoading ? (
            <div className="flex justify-center items-center h-full">
              <Spin size="large" />
            </div>
          ) : chopData.length > 0 ? (
            <ChannelPeakPower chopData={chopData} details={reportDetails} />
          ) : (
            <div>No Data Available</div>
          )}
        </>
      ),
    },
    {
      key: '3',
      title: 'Channel Average Power',
      content: (
        <>
          {isLoading ? (
            <div className="flex justify-center items-center h-full">
              <Spin size="large" />
            </div>
          ) : chopData.length > 0 ? (
            <ChannelAveragePower chopData={chopData} details={reportDetails} />
          ) : (
            <div>No Data Available</div>
          )}
        </>
      ),
    },
  ];

  return (
    <>
      <div className='h-[10vh]'>
        <Navbar page="reports" />
      </div>


      {isLoading === true ? (
        <div className="min-h-[90vh] h-fit bg-black flex flex-col p-5 space-y-5 text-white">
          <ManualSpinnerLoader componentName={"Reports"} />
        </div>
      ) : (
        <div className="min-h-[90vh] h-fit bg-black flex flex-col p-5 space-y-5 text-white">
          <Select
            allowClear={true}
            style={{ width: '100%' }}
            placeholder="Add Report"
            onChange={handleReportOption}
            options={addReportOptions.map((report: string) => ({
              label: report,
              value: report,
            }))}
          />
          {reportDetails && (
            <div className="bg-gray-800 text-white p-3 rounded-lg">
              <div className="flex flex-row">
                <p style={{ fontSize: "14px" }}><strong>Node Name:</strong> {reportDetails?.nodeName}</p>
                <p style={{ fontSize: "14px", marginLeft: "20px" }}><strong>REC ID:</strong> {reportDetails?.recid}</p>
              </div>
              <div className="flex flex-row">
                <p style={{ fontSize: "14px" }}><strong>Start Time:</strong> {selectedReportOption === "New" ? reportDetails.startTime : parsedStorageData.startTime}</p>
                <p style={{ fontSize: "14px", marginLeft: "20px" }}><strong>End Time:</strong> {selectedReportOption === "New" ? reportDetails.endTime : parsedStorageData.endTime}</p>
              </div>
            </div>
          )}
          <div className="flex-grow">
            <Tabs
              type="editable-card"
              activeKey={activeKey || undefined}
              onChange={(key) => {
                setActiveKey(key);
                const activeTab = tabs.find(tab => tab.key === key);

                if (activeTab && activeTab.details) {
                  setReportDetails(activeTab.details);
                } else if (reportDetails) {
                  setReportDetails({
                    recid: parsedStorageData.jobRecId,
                    nodeName: parsedStorageData.nodename,
                    startTime: parsedStorageData.startTime,
                    endTime: parsedStorageData.endTime,
                  });
                }
              }}
              onEdit={(targetKey, action) => {
                if (action === 'remove') {
                  removeTab(targetKey as string);
                }
              }}
              hideAdd
            >
              {tabs.map((tab) => (
                <TabPane tab={tab.title} key={tab.key}>
                  {tab.content}
                </TabPane>
              ))}
            </Tabs>

          </div>
        </div>
      )}





      <ReportsModal
        isModalVisible={isModalVisible}
        setIsModalVisible={setIsModalVisible}
        selectedReportOption={selectedReportOption}
      />
    </>
  );
};

export default Reports;
