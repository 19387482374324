import { Layout, Button, Modal, Avatar, Tooltip } from 'antd';
import { LeftOutlined, CloseOutlined, UserOutlined } from '@ant-design/icons';
import { useNavigate } from 'react-router-dom';
import logo from "../assest/dgs.png";
import { AuthContext } from './Login/AuthProvider';
import { useContext, useState, useEffect } from 'react';
import { Utilities } from '../Utilities';

const Navbar = ({ page }: any) => {
  const { auth } = useContext(AuthContext);
  const utils = new Utilities();

  const { Header } = Layout;
  const navigate = useNavigate();
  const { logout } = useContext(AuthContext);
  const [userDetails, setUserDetails] = useState<any>(null);
  const [isLogoutModalVisible, setIsLogoutModalVisible] = useState(false);

  const onCrossButtonClick = () => {
    setIsLogoutModalVisible(true);
  };

  useEffect(() => {
    if (auth.token) {
      const decodedUserDetails = utils.jwtDecode(auth.token);
      setUserDetails(decodedUserDetails);
    }
  }, [auth.token]);

  const backTo = (page: string) => {
    switch (page) {
      case 'manageSites':
      case 'manageNodes':
      case 'manageUsers':
        navigate('/organizations');
        break;

      case 'organizations':
      case 'reportsDashboard':
      case 'displays':
      case 'settings':
        navigate('/collections');
        break;


      case 'reports':
        navigate('/reports-dashboard');
        break;

      default:
        break;
    }
  }

  return (
    <>
      <Header className="bg-gray-800 text-white flex justify-between items-center p-3" style={{ height: '70px' }}>
        {page !== "collections" &&
          <Button
            type="text"
            icon={<LeftOutlined />}
            className="text-white"
            onClick={() => backTo(page)}
          />
        }

        <img
          src={logo}
          alt="Logo"
          style={{ width: '100px', height: 'auto', cursor: page !== "collections" ? 'pointer' : 'default' }}
          onClick={() => page !== "collections" ? navigate("/collections") : null}
        />

        <div className="flex justify-center items-center w-full" style={{ fontWeight: 'bold', fontSize: '20px', marginLeft: 70 }}>
          {"SIGBase\u2122 System"}
        </div>

        {userDetails && (
          <div style={{ display: 'flex', alignItems: 'center', width: '200px', fontSize: 14 }}>
            <div style={{ display: 'flex', alignItems: 'center', marginBottom: '0', overflow: 'hidden' }}>
              <Avatar icon={<UserOutlined />} style={{ marginRight: '8px', fontSize: 12 }} />
              <span
                style={{
                  maxWidth: '100px',  // Set max width for truncation
                  overflow: 'hidden',
                  textOverflow: 'ellipsis',
                  whiteSpace: 'nowrap'
                }}
              >
                {userDetails.userName}
              </span>
            </div>

            <Tooltip title="Logout">
              <Button
                style={{ marginLeft: 30 }}
                type="text"
                icon={<CloseOutlined />}
                className="text-white"
                onClick={onCrossButtonClick}
              />
            </Tooltip>
          </div>
        )}
      </Header>

      <Modal
        title="Logout"
        visible={isLogoutModalVisible}
        okText="Yes"
        onOk={() => { logout(); setIsLogoutModalVisible(false); }}
        cancelText="No"
        onCancel={() => setIsLogoutModalVisible(false)}
        width={500}
      >
        <br />
        Are you sure you want to logout?
      </Modal>

    </>
  );
};

export default Navbar;
