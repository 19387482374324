// SpinnerLoader.tsx
import React from 'react';
import { Spin } from 'antd';

interface ManualSpinnerLoaderProps {
  componentName: string;
}

export const ManualSpinnerLoader: React.FC<ManualSpinnerLoaderProps> = (props) => {

  const incomingComponent = props?.componentName;

  return (
    <>
      {/* {loading && ( */}
      <div>
        {/* Overlay to disable background */}
        <div
          style={{
            position: 'fixed',
            top: 0,
            left: 0,
            width: '100%',
            height: '100%',
            backgroundColor: 'rgba(0, 0, 0, 0.5)', // semi-transparent black background
            zIndex: 9998,
          }}
        />
        {/* Spinner and text */}
        <div
          style={{
            position: 'fixed',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            zIndex: 9999,
            textAlign: 'center',
          }}
        >
          <Spin size="large" />
          <div
            style={{
              marginTop: 10,
              fontSize: '50px',
              color: '#1890ff',
            }}
          >
            Loading...

          </div>
          {incomingComponent === "Displays" ?
            <div
              style={{
                marginTop: 10,
                fontSize: '25px',
                color: '#1890ff',
              }}
            >
              (Displaying the first minute of data. The full dataset is currently being fetched and will be available shortly)
            </div>
            : <></>}

        </div>
      </div>
      {/* )} */}
    </>
  );
};
