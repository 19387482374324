import React, { useContext } from 'react';
import { Navigate } from 'react-router-dom';
import { AuthContext } from './AuthProvider';
import { ManualSpinnerLoader } from '../../ManualSpinnerLoader';

interface ProtectedRouteProps {
  element: React.ReactElement;
}

const ProtectedRoute: React.FC<ProtectedRouteProps> = ({ element }) => {
  const { auth, isLoading } = useContext(AuthContext);

  if (isLoading) {
    // Optionally, show a loading spinner or return null while checking token
    return (
      <ManualSpinnerLoader componentName={'ProtectedRoute'} />
    )
  }

  return auth.token ? element : <Navigate to="/" />;
};

export default ProtectedRoute;
