import React from 'react'

const NoPage = () => {
  return (
    <div className='h-screen'>
      <h1 style={{ color: "white" }} className="capitalize text-center">Page not found</h1>

    </div>
  )
}

export default NoPage
