import { Button, Form, Input, message, Modal } from "antd";
import FormItem from "antd/es/form/FormItem";
import logo from "../../assest/dgs.png";
import { useState, useContext } from "react";
import { fetchToken, resetPassword } from "../../Services";
import { useNavigate } from "react-router-dom";
import { AuthContext } from "./AuthProvider";
import { ManualSpinnerLoader } from "../../ManualSpinnerLoader"; // Ensure correct import
import "./LoginScreen.css"; // Create this CSS file for hover and focus effects

const containerStyle: React.CSSProperties = {
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  height: "100vh",
  backgroundColor: "#000",
  backgroundImage: 'url("/path-to-background-image.jpg")',
  backgroundSize: "cover",
  backgroundPosition: "center",
  position: "relative",
};

const overlayStyle: React.CSSProperties = {
  position: "absolute",
  top: 0,
  left: 0,
  width: "100%",
  height: "100%",
  backgroundColor: "rgba(0, 0, 0, 0.6)", // Dark overlay for readability
  zIndex: 1,
};

const logoStyle: React.CSSProperties = {
  position: "absolute",
  top: "5%",
  width: "120px",
  height: "auto",
  zIndex: 2,
};

const formContainerStyle: React.CSSProperties = {
  background: "rgba(255, 255, 255, 0.1)", // Transparent background
  padding: "40px",
  borderRadius: "15px",
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  width: "350px",
  zIndex: 2, // Above the overlay
  boxShadow: "0px 4px 15px rgba(0, 0, 0, 0.6)", // Subtle shadow for depth
};

const buttonDefaultStyle: React.CSSProperties = {
  padding: "12px",
  backgroundColor: "#f44336",
  color: "white",
  border: "none",
  borderRadius: "8px",
  cursor: "pointer",
  width: "100%",
  fontSize: "16px",
  fontWeight: "bold",
  transition: "background-color 0.3s ease, transform 0.2s ease", // Smooth transition on hover and click
};

const buttonHoverStyle: React.CSSProperties = {
  ...buttonDefaultStyle,
  backgroundColor: "#d32f2f",
  transform: "scale(1.05)", // Slight zoom effect on hover
};

const LoginScreen = () => {
  const [form] = Form.useForm();
  const [hover, setHover] = useState(false);
  const [loading, setLoading] = useState(false);
  const [emailFocus, setEmailFocus] = useState(false); // For email focus
  const [passwordFocus, setPasswordFocus] = useState(false); // For password focus
  const [isModalVisible, setIsModalVisible] = useState(false); // State for modal visibility
  const [forgotEmail, setForgotEmail] = useState("");
  const navigate = useNavigate();
  const { login, auth } = useContext(AuthContext);

  const onSubmitClick = async (values: Record<string, string>) => {
    setLoading(true);
    try {
      const { email, password } = values;
      const result = await fetchToken(email, password);
      if (result) {
        const { message: loginResponseMessage, status } = result;
        if (status !== 200) {
          message.error(loginResponseMessage, 3);
          setLoading(false);
          return;
        }
        login(result?.token || "");
        form.resetFields();
        navigate("/collections");
      }
    } catch (error) {
      console.log(error);
      setLoading(false);
    }
  };
  const forgetPassword = async (value: Record<string, string>) => {
    setLoading(true);

    try {
      const result = await resetPassword(value);
      if (result) {
        if (result?.status !== 200) {
          message.error(String(result?.message), 3);
          setLoading(false);
          return;
        }
        message.success(result?.message);
        form.resetFields();
      }
    } catch (error: any) {
      console.log(error);
      message.error(error, 3);
    } finally {
      setIsModalVisible(false);
      setLoading(false);
    }
  };

  if (auth.token) {
    navigate("/collections");
  }

  const showModal = () => {
    setIsModalVisible(true);
  };

  const handleModalSubmit = (values: Record<string, string>) => {
    // message.success(`Password reset link sent to: ${forgotEmail}`);
    // setIsModalVisible(false);
    forgetPassword(values);
  };

  const handleModalCancel = () => {
    setIsModalVisible(false);
  };

  return (
    <div style={containerStyle}>
      <div style={overlayStyle}></div>
      <img style={logoStyle} src={logo} alt="Logo" />
      <div style={formContainerStyle}>
        <Form form={form} onFinish={onSubmitClick} layout="vertical">
          <FormItem
            name="email"
            rules={[{ required: true, message: "Enter Email" }]}
          >
            <Input
              type="email"
              placeholder="Email"
              className={`login-input ${emailFocus ? "focused" : ""}`}
              onFocus={() => setEmailFocus(true)}
              onBlur={() => setEmailFocus(false)}
            />
          </FormItem>

          <FormItem
            name="password"
            rules={[{ required: true, message: "Enter Password" }]}
          >
            <Input.Password
              placeholder="Password"
              className={`login-input ${passwordFocus ? "focused" : ""}`}
              onFocus={() => setPasswordFocus(true)}
              onBlur={() => setPasswordFocus(false)}
            />
          </FormItem>

          <p
            className="text-white underline"
            style={{
              textAlign: "right",
              cursor: "pointer",
              marginBottom: "15px",
            }}
            onClick={showModal} // Open modal on click
          >
            forgot password?
          </p>

          {loading ? (
            <ManualSpinnerLoader componentName="LoginForm" /> // Pass required prop
          ) : (
            <Button
              htmlType="submit"
              style={hover ? buttonHoverStyle : buttonDefaultStyle}
              onMouseEnter={() => setHover(true)}
              onMouseLeave={() => setHover(false)}
              className="h-14"
            >
              Submit
            </Button>
          )}
        </Form>

        {/* Modal for Forgot Password */}
        <Modal
          title="Forgot Password"
          visible={isModalVisible}
          onCancel={handleModalCancel}
          footer={null}
          closeIcon={<span style={{ cursor: "pointer" }}>X</span>}
        >
          <Form onFinish={handleModalSubmit} layout="vertical">
            <FormItem
              label="Enter your email"
              name="email"
              rules={[{ required: true, message: "Please input your email!" }]}
            >
              <Input
              // type="email"
              // placeholder="Email"
              // value={forgotEmail}
              // onChange={(e) => setForgotEmail(e.target.value)}
              />
            </FormItem>

            <FormItem
              label="Enter your username"
              name="userName"
              rules={[
                { required: true, message: "Please input your User Name!" },
              ]}
            >
              <Input
              // type="username"
              // placeholder="User Name"
              // value={forgotEmail}
              // onChange={(e) => setForgotEmail(e.target.value)}
              />
            </FormItem>

            <FormItem
              label="Enter your Contact Number"
              name="phoneNumber"
              rules={[
                {
                  required: true,
                  message: "Please input your Contact Number!",
                },
              ]}
            >
              <Input
              // type="contact"
              // placeholder="Mobile"
              // value={forgotEmail}
              // onChange={(e) => setForgotEmail(e.target.value)}
              />
            </FormItem>
            <div style={{ display: "flex", justifyContent: "space-between" }}>
              <Button type="default" onClick={handleModalCancel}>
                Cancel
              </Button>
              <Button type="primary" htmlType="submit">
                Submit
              </Button>
            </div>
          </Form>
        </Modal>
      </div>
    </div>
  );
};

export default LoginScreen;
