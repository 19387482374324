import {
  Checkbox,
  DatePicker,
  message,
  Radio,
  TimePicker,
  Typography,
} from "antd";
import { useState, useEffect, useContext } from "react";
import styled from "styled-components";
import {
  fetchSites,
  fetchNodesBySiteId,
  fetchSitesByUserId,
  fetchNodesByUserIdAndSiteId
} from "../../Services";
import dayjs, { Dayjs } from "dayjs";
import { Utilities } from "../../Utilities";
import { AuthContext } from "../Login/AuthProvider";
import { Role } from "../../enums";

const { Title } = Typography;

const Button = styled.button`
  padding: 2px;
  background-color: grey;
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  width: 40%;
  font-size: 16px;
  margin-top: 20px;

  &:hover {
    background-color: #d32f2f;
  }

  &:disabled {
    background-color: grey;
    cursor: not-allowed;
  }
`;

const RowContainer = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 16px;
`;

const Filters = ({
  handleSiteChange,
  handleNodesChange,
  handleStartTime,
  handleStopTime,
}: any) => {
  const [selectedSite, setSelectedSite] = useState<string | null>(null);
  const [handleSitesOnChangeNodes, sethandleSitesOnChangeNodes] = useState<
    string[]
  >([]);
  const [selectedNodes, setSelectedNodes] = useState<any>([]);
  const [sites, setSites] = useState<any[]>([]); // State to store sites fetched from API
  const [startInputDate, setStartInputDate] = useState<Dayjs | null>(null);
  const [stopInputDate, setStopInputDate] = useState<Dayjs | null>(null);
  const [startInputTime, setStartInputTime] = useState<Dayjs | null>(null);
  const [stopInputTime, setStopInputTime] = useState<Dayjs | null>(null);
  const [userRole, setUserRole] = useState<any>({});
  const [userId, setUserId] = useState<number | null>(null);

  const utils = new Utilities();
  const { auth } = useContext(AuthContext);


  const loadSites = async (userId: number, role: string) => {

    if (role == Role.SUPER_ADMIN) {
      const siteData = await fetchSites();
      setSites(siteData);
    } else {
      setUserId(userId);
      const siteData = await fetchSitesByUserId(userId);
      setSites(siteData);
    }
  };

  useEffect(() => {

    if (auth.token) {
      const decodedToken = utils.jwtDecode(auth.token);

      if (decodedToken?.id) {
        setUserRole(decodedToken?.role);
        loadSites(decodedToken.id, decodedToken?.role); // Use decodedUserRole.id directly here
      }
    }
  }, []);

  useEffect(() => {
    const siteToSelect = localStorage
      .getItem("selectedSite")
      ?.trim()
      .replace(/^"|"$/g, "");
    const matchedSite = sites.find((site) => site.name === siteToSelect);
    if (matchedSite) {
      setSelectedSite(matchedSite.name); // Set the matched site's name as the selected site
    }
  }, [sites]);

  useEffect(() => {
    if (selectedSite) {
      handleSitesOnChange(selectedSite, "preserved");
    }
  }, [selectedSite]); // Runs whenever selectedSite changes

  useEffect(() => {
    const storedStartDate = localStorage.getItem("preservedStartDate");
    const storedStopDate = localStorage.getItem("preservedStopDate");
    const storedStartTime = localStorage.getItem("preservedStartTime");
    const storedStopTime = localStorage.getItem("preservedStopTime");
    const storedNodes = localStorage.getItem("selectedNodes");

    if (storedStartDate) {
      // Convert the stored date (in string format) to a Dayjs object
      const parsedStartDate = dayjs(storedStartDate);
      if (parsedStartDate.isValid()) {
        setStartInputDate(parsedStartDate);
      }
    }

    if (storedStopDate) {
      // Convert the stored date (in string format) to a Dayjs object
      const parsedStopDate = dayjs(storedStopDate);
      if (parsedStopDate.isValid()) {
        setStopInputDate(parsedStopDate);
      }
    }

    if (storedStartTime) {
      // Convert the stored date (in string format) to a Dayjs object
      const parsedStartTime = dayjs(storedStartTime);
      if (parsedStartTime.isValid()) {
        setStartInputTime(parsedStartTime);
      }
    }

    if (storedStopTime) {
      // Convert the stored date (in string format) to a Dayjs object
      const parsedStopTime = dayjs(storedStopTime);
      if (parsedStopTime.isValid()) {
        setStopInputTime(parsedStopTime);
      }
    }

    if (storedNodes) {
      // Convert the stored date (in string format) to a Dayjs object
      const parsedNodes = JSON.parse(storedNodes);

      setSelectedNodes(parsedNodes);
    }
  }, []);

  const handleDateTimeOnChange = (date: Dayjs | null, key: string) => {
    if (key === "preservedStartDate") {
      setStartInputDate(date);
    }
    if (key === "preservedStopDate") {
      setStopInputDate(date);
    }
    if (key === "preservedStartTime") {
      setStartInputTime(date);
    }
    if (key === "preservedStopTime") {
      setStopInputTime(date);
    }

    // Save the selected date to localStorage in ISO string format
    if (date) {
      localStorage.setItem(key, date.toISOString());
    } else {
      localStorage.removeItem(key);
    }
  };

  const handleSitesOnChange = async (siteName: string, key: string) => {
    const selectedSite = sites.find(
      (s) => s.name.toLowerCase() === siteName.toLowerCase()
    );

    if (selectedSite) {
      setSelectedSite(siteName);
      if (key === "onChange") {
        setSelectedNodes([]);
        localStorage.removeItem("selectedNodes");
      }

      try {
        let nodes = [];
        // Fetch nodes for the selected site using the siteId
        if (userRole === Role.SUPER_ADMIN) {

          nodes = await fetchNodesBySiteId(selectedSite.id);

        }
        else {
          nodes = await fetchNodesByUserIdAndSiteId(userId || 0, selectedSite.id);
        }


        // Check if nodes is an array before proceeding
        if (Array.isArray(nodes)) {
          // Set node aliases or fallback to node names
          const nodesWithAliases = nodes
            .map((node: any) =>
              node.alias && node?.alias?.trim() !== "" ? node.alias : node.name
            ) // Use alias if available, otherwise fallback to name
            .filter(Boolean); // Remove any empty strings

          sethandleSitesOnChangeNodes(nodesWithAliases);

          const updatedSite = siteName;
          localStorage.setItem("selectedSite", JSON.stringify(updatedSite));
          handleSiteChange(updatedSite);
        } else {
          sethandleSitesOnChangeNodes([]);
        }
      } catch (error) {
        sethandleSitesOnChangeNodes([]);
      }
    }
  };

  const handleNodesOnChange = (values: string[]) => {
    setSelectedNodes(values);

    localStorage.setItem("selectedNodes", JSON.stringify(values));
    handleNodesChange(values);
  };

  const handleReset = () => {
    localStorage.removeItem("selectedNodes");
    localStorage.removeItem("selectedSite");
    localStorage.removeItem("startDateTime");
    localStorage.removeItem("stopDateTime");

    localStorage.removeItem("preservedStartDate");
    localStorage.removeItem("preservedStopDate");
    localStorage.removeItem("preservedStartTime");
    localStorage.removeItem("preservedStopTime");

    handleSiteChange(null);
    handleNodesChange([]);
    handleStartTime(null);
    handleStopTime(null);

    setStartInputDate(null);
    setStopInputDate(null);
    setStartInputTime(null);
    setStopInputTime(null);
    setSelectedSite(null);
    setSelectedNodes([]);
  };

  const combineDateAndTime = (
    date: dayjs.Dayjs | null,
    time: dayjs.Dayjs | null
  ): Date | null => {
    if (!date || !time) return null;

    const combinedDate = new Date(date.toDate()); // Convert dayjs to native JS Date
    combinedDate.setHours(time.hour(), time.minute(), time.second(), 0); // Set hours, minutes, seconds
    return combinedDate;
  };

  const formatDateTime = (date: Date | null): string => {
    if (!date) return "";

    const year = date.getFullYear();
    const month = (date.getMonth() + 1).toString().padStart(2, "0"); // Month is zero-indexed
    const day = date.getDate().toString().padStart(2, "0");
    const hours = date.getHours().toString().padStart(2, "0");
    const minutes = date.getMinutes().toString().padStart(2, "0");
    const seconds = date.getSeconds().toString().padStart(2, "0");

    return `${year}-${month}-${day}T${hours}:${minutes}:${seconds}Z`;
  };

  const handleStopTimeOnChange = (stopTime: Dayjs | null, key: string) => {
    if (
      startInputDate !== null &&
      startInputTime !== null &&
      stopInputDate !== null &&
      stopTime !== null
    ) {
      const stopTimeObject = stopTime;
      setStopInputTime(stopTimeObject);

      // Save the selected date to localStorage in ISO string format
      if (stopTimeObject) {
        localStorage.setItem(key, stopTimeObject.toISOString());
      } else {
        localStorage.removeItem(key);
      }

      const startDateTimeCombined = combineDateAndTime(
        startInputDate,
        startInputTime
      );
      const startDateTimeStr = formatDateTime(startDateTimeCombined);

      const stopDateTimeCombined = combineDateAndTime(
        stopInputDate,
        stopTimeObject
      );
      const stopDateTimeStr = formatDateTime(stopDateTimeCombined);

      localStorage.setItem("startDateTime", JSON.stringify(startDateTimeStr));
      localStorage.setItem("stopDateTime", JSON.stringify(stopDateTimeStr));

      handleStartTime(startDateTimeStr);
      handleStopTime(stopDateTimeStr);
    } else {
      return message.error(
        "Select the start date, start time and stop date first"
      );
    }
  };

  return (
    <div className="w-full flex items-center flex-col p-5">
      <h1 className="custom-title text-center">Site Selection</h1>
      <hr className="mb-3 border border-[#2f2f2f] w-full" />
      <Radio.Group
        className="w-full"
        value={selectedSite}
        onChange={(e) => handleSitesOnChange(e.target.value, "onChange")}
      >
        {sites.map((site) => (
          <div key={site.id}>
            <Radio value={site.name}>{site.name}</Radio>
            {selectedSite === site.name && (
              <Checkbox.Group
                className="flex flex-col gap-1 px-6 py-2"
                value={selectedNodes}
                onChange={handleNodesOnChange}
              >
                {handleSitesOnChangeNodes.map((node) => (
                  <Checkbox key={node} value={node}>
                    {node}
                  </Checkbox>
                ))}
              </Checkbox.Group>
            )}
          </div>
        ))}
      </Radio.Group>
      <br />
      <h1 className="custom-title text-center">Date/Time</h1>
      <hr className="mb-3 border border-[#2f2f2f] w-full" />
      <RowContainer>
        <div style={{ width: "45%" }}>
          <Title level={5} style={{ color: "#fff" }}>
            Start Date
          </Title>
          <DatePicker
            format="MM/DD/YYYY" // Display format in picker
            allowClear={true}
            value={startInputDate}
            style={{ width: "110%" }}
            onChange={(date) =>
              handleDateTimeOnChange(date, "preservedStartDate")
            }
            disabled={selectedSite === null || selectedNodes.length === 0}
          />
        </div>
        <div style={{ width: "45%" }}>
          <Title level={5} style={{ color: "#fff" }}>
            Stop Date
          </Title>
          <DatePicker
            format="MM/DD/YYYY" // Display format in picker
            allowClear={true}
            value={stopInputDate}
            style={{ width: "110%" }}
            onChange={(date) =>
              handleDateTimeOnChange(date, "preservedStopDate")
            }
            disabled={selectedSite === null || selectedNodes.length === 0}
          />
        </div>
      </RowContainer>

      {/* Row for Start Time and Stop Time */}
      <RowContainer>
        <div style={{ width: "48%" }}>
          <Title level={5} style={{ color: "#fff" }}>
            Start Time
          </Title>
          <TimePicker
            format="HH:mm:ss"
            allowClear={true}
            value={startInputTime}
            style={{ width: "100%" }}
            onChange={(time) =>
              handleDateTimeOnChange(time, "preservedStartTime")
            }
            disabled={selectedSite === null || selectedNodes.length === 0}
          />
        </div>
        <div style={{ width: "48%" }}>
          <Title level={5} style={{ color: "#fff" }}>
            Stop Time
          </Title>
          <TimePicker
            format="HH:mm:ss"
            allowClear={true}
            value={stopInputTime}
            style={{ width: "100%" }}
            onChange={(time) =>
              handleStopTimeOnChange(time, "preservedStopTime")
            }
            disabled={selectedSite === null || selectedNodes.length === 0}
          />
        </div>
      </RowContainer>

      <div
        className="sidebar-bottom"
        style={{ textAlign: "center", marginTop: "320px" }}
      >
        <Button
          style={{ width: "150px", height: "30px" }}
          onClick={handleReset}
        >
          Reset
        </Button>
      </div>
    </div>
  );
};

export default Filters;
