import { Select, Button, Tooltip } from "antd";
import Cookies from "js-cookie";
import { Utilities } from "../../Utilities";
import {
  getPsdRangeData,
  getJobCVChopData,
  getChopSliceInfo,
} from "../../Services"; // Import the service functions
import { useState, useEffect } from "react";
const CollectionsCollapsedListing = ({
  type,
  storageData,
  setStorageData,
  record,
  selectedRecId,
}: any) => {
  const utils = new Utilities();
  const timeSettings = Cookies.get("TimeSettings");
  const timeSettingscokkies = timeSettings
    ? JSON.parse(timeSettings)
    : { value: 15, unit: "minutes" }; // Default fallback value

  const [psdTimeSlicesOptions, setPsdTimeSlicesOptions] = useState<string[]>(
    []
  );
  const [chopTimeSlicesOptions, setChopTimeSliceOptions] = useState<string[]>(
    []
  );
  const [psdCheck, setPsdCheck] = useState<string | null>(null);
  const [chopCheck, setChopCheck] = useState<string | null>(null);

  const handleTime = (value: string, dropDownKey: string) => {
    const selectedTime = value;
    const endTime = utils.calculateEndTime(selectedTime, timeSettings);

    const newStorageData = { ...storageData };
    newStorageData.count = 1;
    newStorageData.jobRecId = record.recId;
    newStorageData.nodename = record.nodeName;
    newStorageData.dropDownKey = dropDownKey;
    newStorageData.startTime = selectedTime;
    newStorageData.endTime = endTime;
    newStorageData.maskStartTime = selectedTime;
    newStorageData.maskEndTime = endTime;
    newStorageData.threshold = 200;
    newStorageData.sdrGain = record.descriptionDetails.sdrGain;
    newStorageData.bandwidth = record.bandwidth;
    newStorageData.frequency = record.centerFrequency;
    newStorageData.sampleRate = record.descriptionDetails.sampleRate;
    newStorageData.operations = "psd";
    newStorageData.descritionDetails = record.descriptionDetails;
    setStorageData(newStorageData);
  };

  const handlePsdClick = async (selectedRecId: number) => {
    try {
      if (selectedRecId !== record.recId) {
        return;
      }
      const psdRange = await getPsdRangeData(record.recId);

      setPsdCheck("Y");

      const timeSlices = utils
        .createTimeSlices(psdRange.start, psdRange.end)
        .map((time) => time.toISOString());
      setPsdTimeSlicesOptions(timeSlices);
    } catch (error) {
      console.error("Error fetching PSD range:", error);
      setPsdCheck("N");
      console.log(`REC ID: ${record.recId} has no PSD Data`);
    }
  };

  const handleChopClick = async (selectedRecId: number) => {
    try {
      if (selectedRecId !== record.recId) {
        return;
      }
      const chopData = await getJobCVChopData(record.recId);

      const bandwidths = chopData.map((slice: any) => slice.width);
      const frequencies = chopData.map((slice: any) => slice.frequency);

      const response = await getChopSliceInfo(
        record.recId,
        bandwidths,
        frequencies
      );
      setChopCheck("Y");

      if (response.start_time && response.end_time) {
        // Treat the times as UTC by appending 'Z'
        const startTime = new Date(response.start_time + "Z").toISOString();
        const endTime = new Date(response.end_time + "Z").toISOString();

        const timeSlices = utils
          .createTimeSlices(startTime, endTime)
          .map((time) => time.toISOString());
        setChopTimeSliceOptions(timeSlices);
      } else {
        console.error("Invalid start or end time:", response);
      }
    } catch (error) {
      console.error("Error fetching CHOP data:", error);
      setChopCheck("N");
      console.log(`REC ID: ${record.recId} has no CHOP Data`);
    }
  };

  // Fetch PSD and CHOP data on component mount when type is 'TimeSliceDetails'
  useEffect(() => {
    if (type === "TimeSliceDetails") {
      handlePsdClick(selectedRecId);
      handleChopClick(selectedRecId);
    }
  }, [type, selectedRecId]); // Only re-run effect if `type` changes

  if (type === "DescriptionDetails") {
    const details = record.descriptionDetails;
    return (
      <div className="flex items-center justify-center">
        <div
          className="grid grid-cols-4"
          style={{ padding: "10px", gap: "15px" }}
        >
          <p className="whitespace-nowrap">
            <strong>Collect Start:</strong> {details.collectStart}
          </p>
          <p className="whitespace-nowrap">
            <strong>Collect Stop:</strong> {details.collectStop}
          </p>
          {/* <p className="whitespace-nowrap"><strong>RECID:</strong> {details.recId}</p> */}
          <p className="whitespace-nowrap"><strong>Center Frequency:</strong> {`${(details.centerFrequency / 1000000).toFixed(4)} MHz`}</p>
          <p className="whitespace-nowrap"><strong>Bandwidth:</strong> {`${(details.bandwidth / 1000000).toFixed(4)} MHz`}</p>
          <p className="whitespace-nowrap"><strong>Sample Rate:</strong> {`${(details.sampleRate / 1000000).toFixed(4)} MSPS`}</p>
          <p className="whitespace-nowrap"><strong>SDR Use AGC:</strong> {details.sdrUseAgc}</p>
          <p className="whitespace-nowrap"><strong>SDR Gain:</strong> {`${details.sdrGain} dB`}</p>
          {/* <p className="whitespace-nowrap"><strong>PSD:</strong> {details.psd}</p>
          <p className="whitespace-nowrap"><strong>CHOP:</strong> {details.chop}</p> */}
          <p className="whitespace-nowrap">
            <strong>RDSA:</strong>{" "}
            {details.rdsa !== "null" ? `${details.rdsa} dB` : details.rdsa}
          </p>
          <p className="whitespace-nowrap">
            <strong>FDSA:</strong>{" "}
            {details.fdsa !== "null" ? `${details.fdsa} dB` : details.rdsa}{" "}
          </p>

          <p className="whitespace-nowrap"><strong>LNA:</strong> {details.lna}</p>
          <p className="whitespace-nowrap"><strong>LMR Decode:</strong> {details.lmrDecode}</p>
          <p className="whitespace-nowrap"><strong>LTE Decode:</strong> {details.lteDecode}</p>
          <p className="whitespace-nowrap"><strong>Location Data:</strong> {details.locationData}</p>
          <p className="whitespace-nowrap"><strong>Receiver Number:</strong> {details.receiverNumber}</p>
        </div>
      </div>
    );
  } else if (type === "TimeSliceDetails") {
    return (
      <div style={{ padding: "10px" }}>
        <div>
          <Tooltip
            title={
              <div>
                <div style={{ fontWeight: "bold" }}>Power Spectrum Data</div>
                <div>Used for Spectral Display and Waterfall Display</div>
                <div style={{ marginTop: "8px", fontWeight: "bold" }}>
                  Time Slice Size
                </div>
                <div>
                  {`${timeSettingscokkies.value} `}
                  {`${timeSettingscokkies.unit} `}
                </div>
              </div>
            }
          >
            <Select
              style={{ width: 320 }}
              placeholder="PSD"
              onChange={(e) => handleTime(e, "psd")}
              options={psdTimeSlicesOptions.map((time: any, index: number) => ({
                label: `PSD Time Slice ${index + 1}: ${time}`,
                value: time,
              }))}
              disabled={psdCheck === null || psdCheck !== "Y"}
            />
          </Tooltip>

          <Tooltip
            title={
              <div>
                <div style={{ fontWeight: "bold" }}>Channel Occupancy Data</div>
                <div>Used for Channelized Occupancy and Reports</div>
                <div style={{ marginTop: "8px", fontWeight: "bold" }}>
                  Time Slice Size
                </div>
                <div>
                  {`${timeSettingscokkies.value} `}
                  {`${timeSettingscokkies.unit} `}
                </div>
              </div>
            }
          >
            <Select
              style={{ width: 320, marginLeft: "10px" }}
              allowClear={true}
              placeholder="CHOP"
              onChange={(e) => handleTime(e, "chop")}
              options={chopTimeSlicesOptions.map(
                (time: any, index: number) => ({
                  label: `CHOP Time Slice ${index + 1}: ${time}`,
                  value: time,
                })
              )}
              disabled={chopCheck === null || chopCheck !== "Y"}
            />
          </Tooltip>

          <Tooltip title="SVG Data">
            <Select
              style={{ width: 120, marginLeft: "10px" }}
              allowClear={true}
              placeholder="SVG"
              disabled
            />
          </Tooltip>

          <Tooltip title="LTE Data">
            <Select
              style={{ width: 120, marginLeft: "10px" }}
              allowClear={true}
              placeholder="LTE"
              disabled
            />
          </Tooltip>

          <Tooltip title="LMR Data">
            <Select
              style={{ width: 120, marginLeft: "10px" }}
              allowClear={true}
              placeholder="LMR"
              disabled
            />
          </Tooltip>

          <Tooltip title="Location Data">
            <Select
              style={{ width: 120, marginLeft: "10px" }}
              allowClear={true}
              placeholder="LOCATION"
              disabled
            />
          </Tooltip>

          <Tooltip title="IQ Data">
            <Select
              style={{ width: 120, marginLeft: "10px" }}
              allowClear={true}
              placeholder="IQ"
              disabled
            />
          </Tooltip>
        </div>
      </div>
    );
  }

  return null;
};

export default CollectionsCollapsedListing;
