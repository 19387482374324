import { useState, useEffect, useContext } from "react";
import { useLocation } from "react-router-dom";
import Navbar from "../Navbar";
import {
  Typography,
  Table,
  Button,
  Tag,
  Modal,
  Form,
  Input,
  Select,
  message,
  notification,
  Switch,
  InputNumber,
  Checkbox,
} from "antd";
import { UserOutlined } from "@ant-design/icons";
import {
  fetchUsersByOrganizationId,
  createSite,
  linkOrganizationSite,
  updateSite,
  createUser,
  updateUser,
  changeUserPassword,
  fetchSitesByOrganizationId,
  createUserSite,
  createUserNode,
  fetchUserSitesByUserId,
  fetchUserNodesByUserIdAndSiteId,
  deleteUserSite,
  deleteUserNode,
} from "../../Services"; // Import createSite and updateSite API functions
import { AuthContext } from "../Login/AuthProvider";
import { Utilities } from "../../Utilities";
import { Role } from "../../enums";

// Define the interface for the API response
interface Node {
  id: number;
  name: string;
  alias: string | null;
  isActive: boolean;
}

interface SiteDetails {
  id: number;
  name: string;
  location: string | null;
  isActive: boolean;
  Nodes: Node[];
}

interface Site {
  id: number;
  organizationId: number;
  siteId: number;
  createdOn: string;
  createdBy: string | null;
  modifiedOn: string | null;
  modifiedBy: string | null;
  Organization: { name: string };
  Site: SiteDetails; // Site is nested here
}

const ManageUsers = () => {
  const { auth } = useContext(AuthContext);
  const utils = new Utilities();
  const { Title } = Typography;
  const location = useLocation();
  const { organizationId } = location.state || { organizationId: null };
  const { organizationname } = location.state || { organizationname: null };
  const [dataSource, setDataSource] = useState<any[]>([]);
  const [loading, setLoading] = useState<boolean>(false);
  const [isModalVisible, setIsModalVisible] = useState<boolean>(false); // Modal visibility state
  const [isEditMode, setIsEditMode] = useState<boolean>(false); // To track if it's edit mode
  const [editingSiteId, setEditingSiteId] = useState<number | null>(null); // Store the site being edited
  const [userRole, setUserRole] = useState<string | null>(null);
  const [isActiveCheck, setIsActiveCheck] = useState<boolean>(true);
  const [isStatusModalVisible, setIsStatusModalVisible] =
    useState<boolean>(false);
  const [isChangePasswordModalVisible, setIsChangePasswordModalVisible] =
    useState<boolean>(false); // Change Password Modal state
  const [sites, setSites] = useState<Site[]>([]);
  const [selectedSites, setSelectedSites] = useState<string[]>([]);

  const [selectedNodes, setSelectedNodes] = useState<{
    [siteId: string]: string[];
  }>({});

  const [EditselectedSites, setEditSelectedSites] = useState<string[]>([]);

  const [EditselectedNodes, setEditSelectedNodes] = useState<any[]>([]);

  const [form] = Form.useForm();
  const [selectedRole, setSelectedRole] = useState(Role.USER); // Default role
  const [selectedUserRoleForPasswordChange, setSelectedUserRoleForPasswordChange] = useState<string | null>(null);
  const [isRoleDisabled, setIsRoleDisabled] = useState<boolean>(false);



  const loadUsers = async () => {
    if (organizationId) {
      setLoading(true);
      const userData = await fetchUsersByOrganizationId(organizationId);
      if (userData) {
        const formattedUsers = userData.map((user: any) => ({
          key: user.id,
          email: user.email,
          userName: user.userName,
          phoneNumber: user.phoneNumber ?? "Not Available",
          sites: user.UserSites?.length || 0,
          nodes: user.UserNodes?.length || 0,
          role: user.role,
          organization: organizationname,
          status: user.isActive,
        }));
        setDataSource(formattedUsers);
      }
      setLoading(false);
    }
  };

  // Fetch Sites by OrganizationId
  const fetchSites = async (organizationId: number) => {
    const siteData = await fetchSitesByOrganizationId(organizationId);
    setSites(siteData || []); // Set sites to state
  };

  useEffect(() => {
    if (auth.token) {
      const decodedUserRole = utils.jwtDecode(auth.token)?.role;

      setUserRole(decodedUserRole);
      loadUsers();
      fetchSites(organizationId);
    }
  }, [auth.token, organizationId]);

  const columns = [
    {
      title: "EMAIL",
      dataIndex: "email",
      key: "email",
      sorter: (a: any, b: any) => a.email.localeCompare(b.email)
    },
    {
      title: "USER NAME",
      dataIndex: "userName",
      key: "userName",
      sorter: (a: any, b: any) => a.userName.localeCompare(b.userName)
    },
    {
      title: "PHONE NUMBER",
      dataIndex: "phoneNumber",
      key: "phoneNumber",
      sorter: (a: any, b: any) => a.phoneNumber.localeCompare(b.phoneNumber)
    },
    {
      title: "SITES",
      dataIndex: "sites",
      key: "sites",
      sorter: (a: any, b: any) => a.sites - b.sites
    },
    {
      title: "NODES",
      dataIndex: "nodes",
      key: "nodes",
      sorter: (a: any, b: any) => a.nodes - b.nodes
    },
    {
      title: "ROLE",
      dataIndex: "role",
      key: "role",
      sorter: (a: any, b: any) => a.role.localeCompare(b.role)
    },
    {
      title: "ORGANIZATION",
      dataIndex: "organization",
      key: "organization",
      sorter: (a: any, b: any) => a.organization.localeCompare(b.organization)
    },
    {
      title: "STATUS",
      dataIndex: "status",
      key: "status",
      width: 250, // Set a fixed width for the status column
      render: (status: boolean, record: any) => (
        <>
          <div style={{ display: "flex", gap: "10px", alignItems: "center" }}>
            {auth.role == Role.SUPER_ADMIN || auth.role == Role.ADMIN ? ( // Replace this with your custom condition
              <Switch
                checked={status}
                onChange={(check: boolean) =>
                  handleStatusChange(check, record.key)
                }
              />
            ) : null}

            <Tag
              color={status ? "green" : "red"}
              style={{
                width: "80px", // Set a fixed width for the tag
                textAlign: "center", // Center the text inside the tag
              }}
            >
              {status ? "Active" : "Inactive"}
            </Tag>
          </div>
        </>
      ),
    },
    {
      title: "ACTIONS",
      key: "actions",
      render: (record: any) => (
        <>
          {/* Render the Edit button programmatically based on a condition */}
          {auth.role == Role.SUPER_ADMIN || auth.role == Role.ADMIN ? ( // Replace this with your custom condition
            <Button
              type="default"
              onClick={() => showModal("edit", record.key, record.role)}
            >
              Edit
            </Button>
          ) : null}
          {!(auth.role == Role.USER && record.role === Role.ADMIN) && <Button
            type="default"
            style={{ marginLeft: "10px" }}
            onClick={() => {
              setEditingSiteId(record.key); // Set the user ID here
              setSelectedUserRoleForPasswordChange(record.role); // Save the user role for the modal
              form.setFieldsValue({
                userName: record.userName, // Set the userName in the form
              });
              setIsChangePasswordModalVisible(true); // Show the password modal
            }}
          >
            Change Password
          </Button>}
        </>
      ),
    },
  ];

  const handleUserSiteCreation = async ({
    siteId,
    userId,
  }: {
    siteId: number;
    userId: number;
  }) => {
    try {
      const userSiteData = { siteId, userId };
      const result = await createUserSite(userSiteData); // Call the API to create the user-site link
    } catch (err) {
      console.log(err);
    }
  };

  // Close Modal
  const handleCancel = () => {
    setEditingSiteId(null);
    form.resetFields();
    setIsModalVisible(false);
    setEditSelectedSites([]);
    setEditSelectedNodes([]);
  };

  const handleOk = async () => {
    try {
      const values = await form.validateFields(); // Validate form fields

      // Collect selected nodes
      const selectedNodesArray = Object.entries(selectedNodes).reduce(
        (acc: string[], [siteId, nodes]) => {
          return [...acc, ...nodes]; // Collect all node ids
        },
        []
      );

      const formValues = {
        selectedSites: selectedSites.filter((item, index) =>
          selectedSites.indexOf(item) === index
        ),
        selectedNodes:
          selectedNodesArray.filter((item, index) =>
            selectedNodesArray.indexOf(item) === index
          )
        ,
      };

      if (isEditMode && editingSiteId) {
        const updatedSite = {
          userName: values.userName,
          phoneNumber: values.phoneNumber,
          email: values.email,
          role: values.role,
          organizationId: organizationId,
        };
        const response = await updateUser(editingSiteId, updatedSite);

        if (response && values.role == Role.USER) {

          // Then call deleteUserSite API after updateUser is successful
          const deleteSiteResponse = await deleteUserSite(editingSiteId);


          // Finally, call deleteUserNode API after deleteUserSite is successful
          const deleteNodeResponse = await deleteUserNode(editingSiteId);

          if (deleteSiteResponse && deleteNodeResponse) {

            let selectedSites = EditselectedSites.filter((item, index) =>
              EditselectedSites.indexOf(item) === index
            )
            await Promise.all(
              selectedSites?.map(async (siteId: any) => {
                await handleUserSiteCreation({
                  siteId: parseInt(siteId),
                  userId: editingSiteId,
                });
              })
            );
            await Promise.all(
              EditselectedNodes.map(async (nodeId) => {
                await createUserNode({
                  nodeId: parseInt(nodeId),
                  userId: editingSiteId,
                });
              })
            );
          }

        }
        handleCancel();
        loadUsers();
        message.success(response);
      } else {
        // Add new user and then link the site with the user
        const newUserData = {
          userName: values.userName,
          phoneNumber: values.phoneNumber,
          organizationId: organizationId,
          role: values.role,
          email: values.email,
          password: values.password,
          isActive: true,
        };

        const result = await createUser(newUserData);

        if (result) {

          const { createdUser, message: createdUserMessage, status } = result;


          if (status === 409) {
            message.error(createdUserMessage);
            handleCancel();
            return;
          }

          message.success(createdUserMessage);

          if (selectedSites?.length > 0 && createdUser && createdUser.id && selectedRole == Role.USER) {
            await Promise.all(
              selectedSites.map(async (siteId) => {
                await handleUserSiteCreation({
                  siteId: parseInt(siteId),
                  userId: createdUser.id,
                });
              })
            );

            await Promise.all(
              formValues.selectedNodes.map(async (nodeId) => {
                await createUserNode({
                  nodeId: parseInt(nodeId),
                  userId: createdUser.id,
                });
              })
            );
          }
          handleCancel();
          loadUsers();
        }
      }
    } catch (errorInfo) {
      console.log(errorInfo);
    }
  };

  const handleStatusChange = async (checked: boolean, id: number) => {
    if (checked === false) {
      setIsActiveCheck(checked);
      setEditingSiteId(id);
      setIsStatusModalVisible(true);
    } else {
      const updatedStatus = {
        isActive: checked,
      };

      const updatedUserResponse = await updateUser(id, updatedStatus);
      if (updatedUserResponse) {
        message.success(updatedUserResponse);
        loadUsers();
      }
    }
  };

  const handleStatusUpdate = async () => {
    try {
      if (editingSiteId) {
        const updatedStatus = {
          isActive: isActiveCheck,
        };
        const updatedNodeResponse = await updateUser(
          editingSiteId,
          updatedStatus
        );
        if (updatedNodeResponse) {
          message.success(updatedNodeResponse, 3);
          handleStatusCancel();
          loadUsers();
        }
      }
    } catch (e) { }
  };

  const handleStatusCancel = () => {
    setIsStatusModalVisible(false);
    setIsActiveCheck(true);
    setEditingSiteId(null);
  };

  const handlePasswordCancel = () => {
    setIsChangePasswordModalVisible(false);
  };

  const handleChangePasswordOk = async () => {
    try {
      // Validate the form fields
      const values = await form.validateFields();

      const payload = {
        userId: editingSiteId ?? 0, // Provide a default value for userId if it's null
        oldPassword: (userRole === Role.USER || userRole === Role.ADMIN) ? values.oldPassword : "",
        password: values.newPassword, // Use 'password' for the new password
      };



      // Call the changeUserPassword API
      const result = await changeUserPassword(
        payload.userId,
        payload.oldPassword,
        payload.password
      );

      if (result?.message) {
        message.success(result?.message);
        setIsChangePasswordModalVisible(false); // Close the modal
        form.resetFields(); // Reset form fields
      } else {
        message.error("Old password is not valid.");
      }
    } catch (error) {
      message.error("Please check the input fields");
    }
  };

  const showModal = async (key: string, id?: number, role?: any) => {

    setIsModalVisible(true);
    setIsEditMode(key === "add" ? false : true);

    if (key === "add") {
      setIsEditMode(false);
      form.resetFields();
      setSelectedSites([]); // Clear selected sites in add mode
      setIsRoleDisabled(false); // Enable role selection in add mode
    } else if (key === "edit" && id !== undefined && role !== undefined) {
      setSelectedRole(role);
      setEditingSiteId(id);


      if (role === Role.ADMIN && userRole === Role.ADMIN) {
        setIsRoleDisabled(true); // Disable role selection if editing an admin
      } else {
        setIsRoleDisabled(false); // Enable role selection for non-admin users
      }


      const selectedRecord = dataSource.find((item: any) => item.key === id);

      if (selectedRecord) {
        form.setFieldsValue({
          userName: selectedRecord.userName,
          phoneNumber: selectedRecord.phoneNumber,
          email: selectedRecord.email,
          role: selectedRecord.role,
        });

        try {
          const userSites = await fetchUserSitesByUserId(id);

          if (userSites) {
            const siteIds = userSites.map((site: any) =>
              site.siteId.toString()
            ); // Collect all site IDs as strings

            await Promise.all(
              siteIds.map(async (site: any) => {

                const userNodes = await fetchUserNodesByUserIdAndSiteId(
                  id,
                  Number(site)
                );
                if (userNodes) {
                  const nodeIds = userNodes.map((node: any) =>
                    String(node.nodeId)
                  );
                  setEditSelectedSites((prevSites) => {
                    // Merge the previous and new nodeIds, removing duplicates if necessary
                    return [...prevSites, ...siteIds];
                  });

                  setEditSelectedNodes((prevNodeIds) => {
                    // Merge the previous and new nodeIds, removing duplicates if necessary
                    return [...prevNodeIds, ...nodeIds];
                  });
                }
                else {
                  setEditSelectedSites((prevSites) => {
                    // Merge the previous and new nodeIds, removing duplicates if necessary
                    return [...prevSites, ...siteIds];
                  });
                }
              })
            );
          }

        } catch (error) { }
      }
    }
  };
  // Handle site checkbox change
  const handleSiteEdit = (siteId: string, checked: boolean) => {
    // If the checkbox is checked, add the siteId to the array, otherwise remove it
    const updatedSites = checked
      ? [...EditselectedSites, siteId] // Add siteId if checked
      : EditselectedSites.filter((id) => id !== siteId); // Remove siteId if unchecked

    // Store the updated array in the state
    setEditSelectedSites(updatedSites);

    if (!checked) {
      // Reset the selected nodes for that site if it's unchecked
      setSelectedNodes((prev) => {
        const newNodes = { ...prev };
        delete newNodes[siteId];
        return newNodes;
      });
    }
  };

  const handleSiteChange = (siteId: string, checked: boolean) => {
    const updatedSites = checked
      ? [...selectedSites, siteId] // Add siteId if checked
      : selectedSites.filter((id) => id !== siteId); // Remove siteId if unchecked
    setSelectedSites(updatedSites);

    if (!checked) {
      // Reset the selected nodes for that site if it's unchecked
      setSelectedNodes((prev) => {
        const newNodes = { ...prev };
        delete newNodes[siteId];
        return newNodes;
      });
    }
  };

  // Handle node checkbox change
  const handleNodeChange = (
    siteId: string,
    nodeId: string,
    checked: boolean
  ) => {
    setSelectedNodes((prev) => {
      const currentNodes = prev[siteId] || [];
      const updatedNodes = checked
        ? [...currentNodes, nodeId] // Add nodeId if checked
        : currentNodes.filter((id) => id !== nodeId); // Remove nodeId if unchecked
      return {
        ...prev,
        [siteId]: updatedNodes,
      };
    });
  };
  const handleNodeEdit = (siteId: string, nodeId: string, checked: boolean) => {
    const stringNodeId = String(nodeId); // Convert nodeId to string
    setEditSelectedNodes((prev) => {
      const updatedNodes = checked
        ? prev.includes(stringNodeId)
          ? prev
          : [...prev, stringNodeId] // Add nodeId as string if it's not already present
        : prev.filter((id) => id !== stringNodeId); // Remove nodeId as string if unchecked

      return updatedNodes;
    });
    setEditSelectedSites((prevSites) => {
      // Merge the previous and new nodeIds, removing duplicates if necessary
      return [...prevSites, String(siteId)];
    });
  };


  const handleRoleChange = (value: any) => {
    setSelectedRole(value);
  };

  return (
    <>
      <div className="h-[10vh]">
        <Navbar page={"manageUsers"} />
      </div>
      <div className="h-[190vh] p-5">
        <Title
          style={{
            textAlign: "center",
            marginBottom: 20,

            fontSize: 20,
          }}
        >
          ADMIN SETTINGS
        </Title>
        <div
          style={{
            display: "flex",
            alignItems: "center",
            marginLeft: 40,
            marginBottom: 20,
          }}
        >
          <UserOutlined
            style={{ fontSize: 60, marginRight: 10, color: "white" }}
          />
          <Title level={3} style={{ fontSize: 20, marginBottom: 0 }}>
            USERS
          </Title>
        </div>
        <Table
          style={{ paddingLeft: 40, paddingRight: 40 }}
          dataSource={dataSource}
          columns={columns}
          pagination={false}
          loading={loading}
          bordered={true}
          footer={() =>
            (auth.role == Role.SUPER_ADMIN || auth.role == Role.ADMIN) && (
              <Button type="dashed" onClick={() => showModal("add")}>
                + Add New User
              </Button>
            )
          }
        />
      </div>

      {/* Add/Edit Site Modal */}
      <Modal
        title={isEditMode ? "Edit User" : "Add New User"}
        visible={isModalVisible}
        onOk={handleOk}
        onCancel={handleCancel}
        okText={isEditMode ? "Update" : "Add"}
        cancelText="Cancel"
        width={800}
      >
        <Form form={form} layout="vertical" name="add-user-form">
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              gap: "20px",
              marginTop: "20px",
            }}
          >
            {/* First div for vertical layout (User Name, Email, Password, Role) */}
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                gap: "20px",
                width: 400,
              }}
            >
              {/* User Name */}
              <Form.Item
                name="userName"
                label="User Name"
                rules={[{ required: true, message: "Please enter the user name" }]}
              >
                <Input placeholder="Enter user name" />
              </Form.Item>


              {/* Email */}
              <Form.Item
                name="email"
                label="Email"
                rules={[
                  { required: true, message: "Please enter the email" },
                  { type: "email", message: "Please enter a valid email address" },
                ]}
              >
                <Input placeholder="Enter email" />
              </Form.Item>


              {/* Phone Number */}
              <Form.Item
                name="phoneNumber"
                label="Phone Number"
                rules={[{ required: true, message: "Please enter the Phone Number" }]}
              >
                <InputNumber style={{ width: '100%' }} placeholder="Enter Phone Number" />
              </Form.Item>

              {/* Password (only for adding a new user, not in edit mode) */}
              {!isEditMode && (
                <Form.Item
                  name="password"
                  label="Password"
                  rules={[{ required: true, message: "Please enter the password" }]}
                >
                  <Input.Password placeholder="Enter password" />
                </Form.Item>
              )}

              {/* Role */}
              <Form.Item
                name="role"
                label="Role"
                rules={[{ required: true, message: "Please select the role" }]}
              >
                <Select
                  placeholder="Select role"
                  onChange={handleRoleChange} // Update selected role
                  disabled={isRoleDisabled} // Disable role selection if it's admin
                >
                  <Select.Option value={Role.USER}>User</Select.Option>
                  <Select.Option value={Role.ADMIN}>Admin</Select.Option>
                  {auth.role === Role.SUPER_ADMIN && (
                    <Select.Option value={Role.SUPER_ADMIN}>Super Admin</Select.Option>
                  )}
                </Select>
              </Form.Item>
            </div>

            {/* Conditionally render this div based on selected role */}
            {(selectedRole !== Role.ADMIN && selectedRole !== Role.SUPER_ADMIN) && (
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  gap: "20px",
                  width: 400,
                  marginLeft: 50,
                }}
              >

                <div style={{ fontSize: "14px" }}>
                  Assign Sites and Nodes:
                </div>

                {isEditMode ? (
                  <>
                    {sites.map((site: any) => (
                      <div key={site.Site.id}>
                        <Checkbox
                          value={site.Site.id}
                          checked={EditselectedSites?.includes(
                            String(site.Site.id)
                          )}
                          onChange={(e) =>
                            handleSiteEdit(
                              String(site.Site.id),
                              e.target.checked
                            )
                          }
                        >
                          {site.Site.name}
                        </Checkbox>

                        <div style={{ paddingLeft: 20 }}>
                          {site.Site.Nodes.map((node: any) => (
                            <Checkbox
                              key={node.id}
                              value={node.id}
                              checked={EditselectedNodes?.includes(
                                String(node.id)
                              )}
                              onChange={(e) =>
                                handleNodeEdit(
                                  site.Site.id,
                                  node.id,
                                  e.target.checked
                                )
                              }
                            >
                              {node.name}
                            </Checkbox>
                          ))}
                        </div>
                      </div>
                    ))}
                  </>
                ) : (
                  <>
                    {sites.map((site: any) => (
                      <div key={site.Site.id}>
                        <Checkbox
                          value={site.Site.id}
                          checked={selectedSites.includes(site.Site.id)}
                          onChange={(e) =>
                            handleSiteChange(site.Site.id, e.target.checked)
                          }
                        >
                          {site.Site.name}
                        </Checkbox>

                        {selectedSites.includes(site.Site.id) && (
                          <div style={{ paddingLeft: 20 }}>
                            <Checkbox.Group>
                              {site.Site.Nodes.map((node: any) => (
                                <Checkbox
                                  key={node.id}
                                  value={node.id}
                                  checked={selectedNodes[site.Site.id]?.includes(
                                    node.id
                                  )}
                                  onChange={(e) =>
                                    handleNodeChange(
                                      site.Site.id,
                                      node.id,
                                      e.target.checked
                                    )
                                  }
                                >
                                  {node.name}
                                </Checkbox>
                              ))}
                            </Checkbox.Group>
                          </div>
                        )}
                      </div>
                    ))}
                  </>
                )}


              </div>
            )}
          </div>
        </Form>
      </Modal>


      {/* Change Password Modal */}
      <Modal
        title="Change Password"
        visible={isChangePasswordModalVisible}
        onOk={handleChangePasswordOk}
        onCancel={handlePasswordCancel}
        okText="Submit"
        cancelText="Cancel"
      >
        <Form form={form} layout="vertical">
          {/* Conditionally render the oldPassword field for "admin" role or "user" role */}
          {(userRole === Role.USER || (userRole === Role.ADMIN && selectedUserRoleForPasswordChange === "admin")) && (
            <Form.Item
              name="oldPassword"
              label="Old Password"
              rules={[
                { required: true, message: "Please enter the old password" },
              ]}
            >
              <Input.Password placeholder="Enter old password" />
            </Form.Item>
          )}

          <Form.Item
            name="newPassword"
            label="New Password"
            rules={[{ required: true, message: "Please enter the new password" }]}
          >
            <Input.Password placeholder="Enter new password" />
          </Form.Item>

          <Form.Item
            name="confirmPassword"
            label="Confirm New Password"
            dependencies={["newPassword"]}
            rules={[
              { required: true, message: "Please confirm the new password" },
              ({ getFieldValue }) => ({
                validator(_, value) {
                  if (!value || getFieldValue("newPassword") === value) {
                    return Promise.resolve();
                  }
                  return Promise.reject(
                    new Error("New Password and Confirm Password do not match!")
                  );
                },
              }),
            ]}
          >
            <Input.Password placeholder="Confirm new password" />
          </Form.Item>
        </Form>
      </Modal>


      {/* Deactivate User Modal */}
      <Modal
        title={"Deactivate User"}
        visible={isStatusModalVisible}
        onCancel={handleStatusCancel}
        cancelText={"No"}
        onOk={handleStatusUpdate}
        okText={"Yes"}
      >
        <br />
        Are you sure you want to Deactivate the User?
      </Modal>
    </>
  );
};

export default ManageUsers;
