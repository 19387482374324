import { useEffect, useRef, useState } from 'react';
import * as d3 from 'd3';
import { Switch, Tooltip, } from 'antd';
import { InfoCircleOutlined, } from "@ant-design/icons";
import { Utilities } from '../../Utilities';

const D3Spectral = ({ power, frequencies, times, index, minValue, maxValue, onStateChange }: any) => {
  const utils = new Utilities();
  const [maxHoldData, setMaxHoldData] = useState<number[]>([]);
  const [maxHoldEnabled, setMaxHoldEnabled] = useState(false);
  const [tooltip, setTooltip] = useState<{ x: number, y: number, powerValue: number, frequency: number } | null>(null);
  const [zoomTransform, setZoomTransform] = useState(d3.zoomIdentity); // Track zoom state
  const storageData: any = localStorage.getItem("StorageData");
  const parsedStorageData = JSON.parse(storageData);
  const [isMetadataVisible, setIsMetadataVisible] = useState(false); // Metadata visibility state


  const canvasRef = useRef<HTMLCanvasElement | null>(null);

  useEffect(() => {
    // Whenever some relevant state in D3Spectral changes, call onStateChange to emit it to the parent.
    if (onStateChange) {
      onStateChange({
        zoomTransform
      });
    }
  }, [zoomTransform]);

  useEffect(() => {
    if (maxHoldEnabled && power.length) {
      const newMaxHoldData = maxHoldData.length ? maxHoldData.slice() : new Array(power.length).fill(-Infinity);
      power.forEach((value: number, index: number) => {
        if (value > newMaxHoldData[index]) {
          newMaxHoldData[index] = value;
        }
      });
      setMaxHoldData(newMaxHoldData);
    }
  }, [power, maxHoldEnabled]);

  useEffect(() => {
    if (!canvasRef.current) return;
    const canvas = canvasRef.current;
    const ctx = canvas.getContext('2d');
    if (!ctx) return;

    const width = 1300;
    const height = 500;
    const marginTop = 100;
    const marginRight = 68;
    const marginBottom = 100;
    const marginLeft = 70;

    // Apply zoom transformation to the X scale
    const xScale = zoomTransform.rescaleX(d3.scaleLinear()
      .domain([d3.min(frequencies as number[])!, d3.max(frequencies as number[])!])
      .range([marginLeft, width - marginRight])
    );

    const yScale = d3.scaleLinear()
      .domain([minValue, maxValue])
      .range([height - marginBottom, marginTop]);

    const drawGraph = () => {
      ctx.clearRect(0, 0, width, height);
      ctx.fillStyle = 'white';
      ctx.textAlign = 'center';
      ctx.font = '12px Arial';

      // Draw X-axis ticks
      const xTicks = xScale.ticks(10);
      xTicks.forEach((d) => {
        const x = xScale(d);
        ctx.beginPath();
        ctx.moveTo(x, height - marginBottom);
        ctx.lineTo(x, height - marginBottom + 5); // Tick mark
        ctx.strokeStyle = 'white';
        ctx.stroke();
        ctx.fillText(`${d?.toFixed(1)} MHz`, x, height - marginBottom + 20);
      });

      const yTicks = yScale.ticks(10);
      yTicks.forEach((d) => {
        const y = yScale(d);
        ctx.fillText(`${d} dBm`, marginLeft - 40, y + 4);
      });

      ctx.beginPath();
      ctx.strokeStyle = 'steelblue';
      ctx.lineWidth = 1.5;

      frequencies.forEach((frequency: number, index: number) => {
        const x = xScale(frequency);
        const y = yScale(power[index]);

        if (x >= marginLeft && x <= width - marginRight && y >= marginTop && y <= height - marginBottom) {
          if (index === 0 || (index > 0 && (xScale(frequencies[index - 1]) < marginLeft || yScale(power[index - 1]) < marginTop || yScale(power[index - 1]) > height - marginBottom))) {
            ctx.moveTo(x, y);
          } else {
            ctx.lineTo(x, y);
          }
        }
      });
      ctx.stroke();

      if (maxHoldEnabled) {
        ctx.beginPath();
        ctx.strokeStyle = 'rgba(144, 238, 144, 0.7)';
        ctx.lineWidth = 1.5;

        frequencies.forEach((frequency: number, index: number) => {
          const x = xScale(frequency);
          const y = yScale(maxHoldData[index]);

          if (y >= marginTop && y <= height - marginBottom) {
            if (index === 0 || (index > 0 && yScale(maxHoldData[index - 1]) < marginTop || yScale(maxHoldData[index - 1]) > height - marginBottom)) {
              ctx.moveTo(x, y);
            } else {
              ctx.lineTo(x, y);
            }
          }
        });
        ctx.stroke();
      }
    };

    // Initial draw
    drawGraph();

    const handleMouseMove = (event: MouseEvent) => {
      const mouseX = event.offsetX;
      const mouseY = event.offsetY;

      let closestPointIndex = 0;
      let closestDistance = Infinity;

      frequencies.forEach((frequency: number, index: number) => {
        const x = xScale(frequency);
        const y = yScale(power[index]);
        const distance = Math.sqrt((mouseX - x) ** 2 + (mouseY - y) ** 2);

        if (distance < closestDistance) {
          closestDistance = distance;
          closestPointIndex = index;
        }
      });

      const frequency = frequencies[closestPointIndex];
      const powerValue = power[closestPointIndex];

      const tooltipX = xScale(frequency);
      const tooltipY = yScale(powerValue);

      setTooltip({
        x: tooltipX,
        y: tooltipY,
        powerValue: powerValue,
        frequency: frequency,
      });
    };

    const handleMouseOut = () => {
      setTooltip(null);
    };

    const zoom = d3.zoom()
      .scaleExtent([1, 4])
      .translateExtent([
        [marginLeft, 0], // Minimum X and Y
        [width - marginRight, height] // Maximum X and Y
      ])
      .on('zoom', (event) => {
        setZoomTransform(event.transform);
      });

    d3.select(canvas as any).call(zoom);

    canvas.addEventListener('mousemove', handleMouseMove);
    canvas.addEventListener('mouseout', handleMouseOut);

    return () => {
      canvas.removeEventListener('mousemove', handleMouseMove);
      canvas.removeEventListener('mouseout', handleMouseOut);
    };

  }, [power, maxHoldData, frequencies, maxHoldEnabled, minValue, maxValue, zoomTransform]);

  const metaData = {
    jobRecId: parsedStorageData?.jobRecId,
    centerFrequency: `${(parsedStorageData?.frequency / 1000000).toFixed(4)} MHz`,
    usableBandwidth: `${(parsedStorageData?.bandwidth / 1000000).toFixed(4)} MHz`,
    sampleRate: `${(parsedStorageData?.sampleRate / 1000000).toFixed(4)} MSPS`,
    sdrGain: `${parsedStorageData?.sdrGain} dB`,
    startTime: parsedStorageData?.startTime,
    endTime: parsedStorageData?.endTime,
    sdrUseAgc: parsedStorageData?.descritionDetails?.sdrUseAgc,
    rdsa: parsedStorageData?.descritionDetails?.rdsa !== "null" ? `${parsedStorageData.descritionDetails.rdsa} dB` : parsedStorageData.descritionDetails.rdsa,
    fdsa: parsedStorageData?.descritionDetails?.fdsa !== "null" ? `${parsedStorageData.descritionDetails.fdsa} dB` : parsedStorageData.descritionDetails.fdsa,
    lna: parsedStorageData?.descritionDetails?.lna,
    lmrdecode: parsedStorageData?.descritionDetails?.lmrDecode,
    ltedecode: parsedStorageData?.descritionDetails?.lteDecode,
    location: parsedStorageData?.descritionDetails?.locationData,
    receiverNumber: parsedStorageData?.descritionDetails?.receiverNumber

  }


  return (
    <>
      <div className='grid grid-cols-3 w-full'>


        <div>
          <Switch
            checked={maxHoldEnabled}
            onChange={() => setMaxHoldEnabled(!maxHoldEnabled)}
            checkedChildren="Max Hold Enabled"
            unCheckedChildren="Max Hold Disabled"
          />
        </div>
        <div className='text-center'>
          <label className='text-lg font-semibold' style={{ color: "white" }}>Spectral Display - {`${times[index]}`}</label>
        </div>

        {/* Info Icon */}
        <div className="flex justify-end">
          <Tooltip title="View Metadata">
            <InfoCircleOutlined
              style={{ fontSize: '24px', color: 'white', cursor: 'pointer' }}
              onClick={() => setIsMetadataVisible(!isMetadataVisible)}

            />
          </Tooltip>
        </div>
      </div>
      <br />
      {isMetadataVisible && (
        <div style={{ color: "white", padding: "10px", backgroundColor: "rgba(0,0,0,0.7)" }}>
          <div className="grid grid-cols-4" style={{ padding: '10px', gap: "15px" }}>
            <p className="whitespace-nowrap"><strong>Job Record ID:</strong> {metaData.jobRecId}</p>
            <p className="whitespace-nowrap"><strong>Collect Start:</strong> {metaData.startTime}</p>
            <p className="whitespace-nowrap"><strong>Collect Stop:</strong> {metaData.endTime}</p>
            <p className="whitespace-nowrap"><strong>Center Frequency:</strong> {metaData.centerFrequency}</p>
            <p className="whitespace-nowrap"><strong>Bandwidth:</strong> {metaData.usableBandwidth}</p>
            <p className="whitespace-nowrap"><strong>Sample Rate:</strong> {metaData.sampleRate}</p>
            <p className="whitespace-nowrap"><strong>SDR Use AGC:</strong> {metaData.sdrUseAgc}</p>
            <p className="whitespace-nowrap"><strong>RDSA:</strong> {metaData.rdsa}</p>
            <p className="whitespace-nowrap"><strong>FDSA:</strong> {metaData.fdsa}</p>
            <p className="whitespace-nowrap"><strong>SDR Gain:</strong> {metaData.sdrGain}</p>
            <p className="whitespace-nowrap"><strong>LNA:</strong> {metaData.lna}</p>
            <p className="whitespace-nowrap"><strong>LMR Decode:</strong> {metaData.lmrdecode}</p>
            <p className="whitespace-nowrap"><strong>LTE Decode:</strong> {metaData.ltedecode}</p>
            <p className="whitespace-nowrap"><strong>Location:</strong> {metaData.location}</p>
            <p className="whitespace-nowrap"><strong>Receiver Number:</strong> {metaData.receiverNumber}</p>
          </div>
        </div>
      )}

      <div className='flex items-center justify-center relative'>
        <canvas ref={canvasRef} width={1300} height={500} />
        {tooltip && (
          <div
            style={{
              position: 'absolute',
              left: tooltip.x + 10,
              top: tooltip.y - 10,
              backgroundColor: 'rgba(0, 0, 0, 0.7)',
              color: 'white',
              padding: '5px',
              borderRadius: '5px',
              pointerEvents: 'none',
            }}
          >
            <div>Power: {tooltip?.powerValue?.toFixed(4)} dBm</div>
            <div>Frequency: {tooltip?.frequency?.toFixed(4)} MHz</div>
          </div>
        )}
      </div>
    </>
  );
};

export default D3Spectral;
