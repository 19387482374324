import React, { useEffect, useRef, useState } from "react";
import * as d3 from "d3";
import { Button, Typography } from "antd";

const UtilizationVisualizer: React.FC = () => {
  const svgRef = useRef<SVGSVGElement | null>(null);
  const xScaleRef = useRef<d3.ScaleBand<string> | null>(null);
  const yScaleRef = useRef<d3.ScaleLinear<number, number> | null>(null);

  const [selectedChannels, setSelectedChannels] = useState<{
    [key: string]: any;
  }>({});

  // Zoom scale state
  const [zoomScale, setZoomScale] = useState(1);

  const { Text } = Typography;

  // Adjust scale range based on zoom scale
  const adjustZoom = (direction: "in" | "out") => {
    let newScale = direction === "in" ? zoomScale * 1.1 : zoomScale / 1.1;
    newScale = Math.max(newScale, 1); // Restrict zoom scale to a minimum of 1
    setZoomScale(newScale);
  };

  useEffect(() => {
    // Sample data
    const data = [
      {
        frequency: "200 MHz",
        occupancy: 40,
        probability: 85,
        avgPower: -90,
        peakPower: -120,
      },
      {
        frequency: "250 MHz",
        occupancy: 40,
        probability: 85,
        avgPower: -90,
        peakPower: -120,
      },
      {
        frequency: "250 MHz",
        occupancy: 100,
        probability: 85,
        avgPower: -90,
        peakPower: -120,
      },
      {
        frequency: "800 MHz",
        occupancy: 55,
        probability: 60,
        avgPower: -88,
        peakPower: -118,
      },
      {
        frequency: "1400 MHz",
        occupancy: 70,
        probability: 75,
        avgPower: -85,
        peakPower: -115,
      },
      {
        frequency: "2000 MHz",
        occupancy: 45,
        probability: 65,
        avgPower: -83,
        peakPower: -113,
      },
      {
        frequency: "2600 MHz",
        occupancy: 35,
        probability: 50,
        avgPower: -80,
        peakPower: -110,
      },
      {
        frequency: "3200 MHz",
        occupancy: 60,
        probability: 55,
        avgPower: -78,
        peakPower: -108,
      },
      {
        frequency: "3800 MHz",
        occupancy: 50,
        probability: 40,
        avgPower: -75,
        peakPower: -105,
      },
      {
        frequency: "4400 MHz",
        occupancy: 65,
        probability: 30,
        avgPower: -73,
        peakPower: -103,
      },
      {
        frequency: "4800 MHz",
        occupancy: 42,
        probability: 45,
        avgPower: -70,
        peakPower: -100,
      },
      {
        frequency: "5000 MHz",
        occupancy: 75,
        probability: 80,
        avgPower: -68,
        peakPower: -98,
      },
    ];

    const width = parseInt(d3.select("#svg-data").style("width"), 10);
    const height = 500;
    const marginTop = 20;
    const marginRight = 0;
    const marginBottom = 30;
    const marginLeft = 40;

    // Create the horizontal scale and its axis generator // generateIntervalArray(data, 300)
    const x = d3
      .scaleBand()
      .domain(data.map((d) => d.frequency))
      .range([marginLeft, width - marginRight])
      .padding(0.1);

    // Create the vertical scale
    const y = d3
      .scaleLinear()
      .domain([0, Math.max(...data.map((n) => n.occupancy)) + 10])
      .nice()
      .range([height - marginBottom, marginTop]);

    // Clear previous SVG if it exists
    d3.select(svgRef.current).selectAll("*").remove();

    // Store scales in refs for outside access
    xScaleRef.current = x;
    yScaleRef.current = y;

    // Create the SVG container and call the zoom behavior
    const svg = d3
      .select(svgRef.current)
      .attr("viewBox", `0 0 ${width} ${height}`)
      .attr("width", width)
      .attr("height", height)
      .attr("style", "max-width: 100%; height: auto;")
      .call(zoom);

    // const zoomGroup = svg.append("g").attr("class", "zoom-group");

    // axis with ticks (the lines)
    const xAxis: d3.Axis<string> = d3
      .axisBottom(x)
      //   .scale(x)
      .tickSizeOuter(0)
      .ticks(10)
      .tickSize(-width + marginLeft + marginRight);
    const yAxis = d3
      .axisLeft(y)
      .tickSizeOuter(0)
      .ticks(10)
      .tickSize(-width + marginLeft + marginRight);

    // Append the axes
    svg
      .append("g")
      .attr("class", "x-axis")
      .attr("transform", `translate(0,${height - marginBottom})`)
      .call(xAxis);

    svg
      .append("g")
      .attr("class", "y-axis")
      .attr("transform", `translate(${marginLeft},0)`)
      .call(yAxis);

    // X Axis Label
    svg
      .append("text")
      .attr("x", width / 2)
      .attr("y", height)
      .attr("text-anchor", "middle")
      .style("fill", "#fff")
      .text("Power");

    // Y Axis Label
    svg
      .append("text")
      .attr("transform", "rotate(-90)")
      .attr("x", -height / 2)
      .attr("y", 12)
      .attr("text-anchor", "middle")
      .style("fill", "#fff")
      .text("Occupancy");

    // Append the bars
    const bars = svg
      .append("g")
      .attr("class", "bars")
      .attr("fill", "steelblue")
      .selectAll("rect")
      .data(data)
      .join("rect")
      .attr("fill", "#6BCB77")
      .attr("opacity", 0.7)
      .attr("x", (d) => x(d.frequency)!)
      .attr("y", (d) => y(d.occupancy))
      .attr("height", (d) => y(0) - y(d.occupancy))
      .attr("width", x.bandwidth())
      .attr("stroke", (d, i) => {
        const sameFrequencyData = data.filter(
          (item) => item.frequency === d.frequency
        );

        if (sameFrequencyData.length > 1) {
          if (i === 0) return "none";
          return "#0f931f";
        }
        return "none";
      })
      .attr("stroke-width", (d, i) => {
        const sameFrequencyData = data.filter(
          (item) => item.frequency === d.frequency
        );
        const indexInSameFrequency = sameFrequencyData.findIndex(
          (item) => item === d
        );
        if (sameFrequencyData.length > 1) {
          if (indexInSameFrequency !== 0) {
            return 5; // Set stroke width for the elements that are not the first one
          }
          return 0;
        }
        return 0;
      })
      .on("click", (event, d) => {
        setSelectedChannels((prev) => ({
          ...prev,
          [d.frequency]: d,
        }));
      });

    function applyZoom() {
      x.range([marginLeft, width - marginRight].map((d) => d * zoomScale));
      bars.attr("x", (d) => x(d.frequency)!).attr("width", x.bandwidth());
      svg.selectAll<SVGGElement, unknown>(".x-axis").call(xAxis);
    }

    applyZoom();

    // Zoom behavior
    function zoom(
      svg: d3.Selection<SVGSVGElement | null, unknown, null, undefined>
    ) {
      const extent: [[number, number], [number, number]] = [
        [marginLeft, marginTop],
        [width - marginRight, height - marginTop],
      ];

      const zoomBehavior = d3
        .zoom<SVGSVGElement, unknown>()
        .scaleExtent([1, 8])
        .translateExtent(extent)
        .extent(extent)
        .on("zoom", zoomed);

      (svg as d3.Selection<SVGSVGElement, unknown, null, undefined>).call(
        zoomBehavior
      );

      function zoomed(event: d3.D3ZoomEvent<SVGSVGElement, unknown>) {
        x.range(
          [marginLeft, width - marginRight].map((d) =>
            event.transform.applyX(d)
          )
        );
        svg
          .selectAll<SVGRectElement, (typeof data)[0]>(".bars rect")
          .attr("x", (d) => x(d.frequency)!)
          .attr("width", x.bandwidth());
        svg.selectAll<SVGGElement, unknown>(".x-axis").call(xAxis);
      }
    }
  }, [zoomScale]);

  const closeChannelInfo = (frequency: string) => {
    setSelectedChannels((prev) => {
      const newChannels = { ...prev };
      delete newChannels[frequency];
      return newChannels;
    });
  };

  return (
    <div className="relative">
      <div className="px-4">
        <svg className="p-4" ref={svgRef}></svg>
      </div>
      {/* <svg ref={svgRef}></svg> */}
      <div style={{ marginTop: "-30px", textAlign: "right" }}>
        <Button onClick={() => adjustZoom("out")} type="text">
          <Text className="text-sm">-</Text>
        </Button>
        <Button onClick={() => adjustZoom("in")} type="text">
          <Text className="text-sm">+</Text>
        </Button>
      </div>
      {Object.entries(selectedChannels).map(([frequency, channelData]) => (
        <div
          key={frequency}
          className="font-medium"
          style={{
            position: "absolute",
            left: `${xScaleRef.current!(channelData.frequency)! + 60}px`, // Use ref for xScale
            top: `${yScaleRef.current!(channelData.occupancy) - 60}px`, // Use ref for yScale
            backgroundColor: "#333",
            padding: "15px",
            borderRadius: "8px",
            boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.5)",
            width: "250px",
            border: "1px solid #9B9A9A",
          }}
        >
          <button
            onClick={() => closeChannelInfo(frequency)}
            style={{
              position: "absolute",
              top: "5px",
              right: "5px",
              background: "none",
              border: "none",
              color: "#fff",
              fontSize: "16px",
              cursor: "pointer",
            }}
          >
            &times;
          </button>

          <h4 className="text-lg text-center">Channel Information</h4>
          <p className="text-sm">Center Frequency: {channelData.frequency}</p>

          <div className="text-sm">
            <span>Occupancy:</span>
            <div
              style={{
                backgroundColor: "#444",
                width: "100%",
                height: "25px",
                borderRadius: "5px",
                position: "relative",
                marginBottom: "5px",
              }}
            >
              <div
                style={{
                  width: `${channelData.occupancy}%`,
                  backgroundColor: "red",
                  height: "inherit",
                  borderRadius: "5px 0px 0px 5px",
                  position: "relative",
                }}
              ></div>
              <span
                style={{
                  position: "absolute",
                  left: `${channelData.occupancy}%`,
                  top: "50%",
                  transform: "translate(-50%, -50%)",
                  fontSize: "12px",
                  color: "#fff",
                }}
              >
                {channelData.occupancy}%
              </span>
            </div>
          </div>
          <div className="text-sm">
            <span>Probability:</span>
            <div
              style={{
                backgroundColor: "#444",
                width: "100%",
                height: "25px",
                borderRadius: "5px",
                position: "relative",
                marginBottom: "5px",
              }}
            >
              <div
                style={{
                  width: `${channelData.probability}%`,
                  backgroundColor: "green",
                  height: "inherit",
                  borderRadius: "5px 0px 0px 5px",
                  position: "relative",
                }}
              ></div>
              <span
                style={{
                  position: "absolute",
                  left: `${channelData.probability}%`,
                  top: "50%",
                  transform: "translate(-50%, -50%)",
                  fontSize: "12px",
                  color: "#fff",
                }}
              >
                {channelData.probability}%
              </span>
            </div>
          </div>

          <span className="text-sm">
            Average Power: {channelData.avgPower} dB
          </span>
          <br />
          <span className="text-sm">
            Peak Power: {channelData.peakPower} dB
          </span>
          <button
            className="mt-4"
            style={{
              width: "100%",
              background: "#fff",
              color: "#111",
              height: "30px",
              borderRadius: "10px",
            }}
          >
            More...
          </button>
        </div>
      ))}
    </div>
  );
};

export default UtilizationVisualizer;