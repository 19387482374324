import React, { createContext, useState, useEffect, ReactNode, useCallback } from 'react';
import { Utilities } from '../../Utilities';

// Define types for AuthContext
interface AuthContextType {
  auth: { token: string | null , role:string |null };
  login: (token: string) => void;
  logout: () => void;
  isLoading: boolean;
}

// Initial context value
const initialAuthState: AuthContextType = {
  auth: { token: null , role:null },
  login: () => { },
  logout: () => { },
  isLoading: true,
};

// Create context with the initial state
export const AuthContext = createContext<AuthContextType>(initialAuthState);

interface AuthProviderProps {
  children: ReactNode;
}

export const AuthProvider: React.FC<AuthProviderProps> = ({ children }) => {
  const [auth, setAuth] = useState<{ token: string | null , role:string |null }>({ token: null ,role:null});
  const [isLoading, setIsLoading] = useState(true);
  const utils = new Utilities();

  const logout = useCallback(() => {
    localStorage.removeItem('token');
    setAuth({ token: null ,role :null});
  }, []);

  const checkTokenExpiration = useCallback(() => {
    if (auth.token) {
      const decodedToken = utils.jwtDecode(auth.token);
      const currentTime = Math.floor(Date.now() / 1000); // Current time in seconds
      if (decodedToken.exp && decodedToken.exp <= currentTime) {
        logout(); // Log out if token is expired
      } else {
        // Set timeout to automatically log out when the token expires
        const timeUntilExpiration = (decodedToken.exp - currentTime) * 1000; // Convert seconds to ms
        setTimeout(logout, timeUntilExpiration);
      }
    }
  }, [auth.token, logout]);

  useEffect(() => {
    const token = localStorage.getItem('token');
    const role = utils.jwtDecode(token||'')?.role;
    if (token) {
      setAuth({ token ,role});
      checkTokenExpiration(); // Check the token expiration on initial load
    }
    setIsLoading(false);
  }, [checkTokenExpiration]);

  const login = (token: string) => {
    localStorage.setItem('token', token);
    const role = utils.jwtDecode(token||'')?.role;
    setAuth({ token ,role});
    checkTokenExpiration(); // Set up automatic logout after login
  };

  return (
    <AuthContext.Provider value={{ auth, login, logout, isLoading }}>
      {children}
    </AuthContext.Provider>
  );
};
