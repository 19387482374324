import { useState } from 'react';
import { SettingOutlined, FilterOutlined ,ToolOutlined } from '@ant-design/icons';
import { useNavigate } from 'react-router-dom';
import './Sidebar.css';
import Filters from './Filters';

const Sidebar = ({ handleSiteChange, handleNodesChange, handleStartTime, handleStopTime }: any) => {

  const [isFilterOpen, setIsFilterOpen] = useState<boolean>(false);
  const navigate = useNavigate();



  const onFilterIconClick = () => {
    setIsFilterOpen(!isFilterOpen)
  }

  return (
    <div className={`sidebar ${isFilterOpen ? 'open' : ''}`}>
      <div className="sidebar-header" >

        <div onClick={onFilterIconClick} style={{ backgroundColor: isFilterOpen ? 'blue' : "" }}>
          <FilterOutlined className='sidebar-icon' />
        </div>

        <div onClick={() => navigate('/settings')}>
          <SettingOutlined className="sidebar-icon" />
        </div>

        <div onClick={() => navigate('/organizations')}>
          <ToolOutlined className="sidebar-icon" />
        </div>

      </div>

      {isFilterOpen && (
        <div className='sidebar-content'>
          <div className="sidebar-top">
            <Filters
              handleSiteChange={handleSiteChange}
              handleNodesChange={handleNodesChange}
              handleStartTime={handleStartTime}
              handleStopTime={handleStopTime}
            />

          </div>
        </div>
      )}

    </div>
  );
};

export default Sidebar;
