import { useState, useEffect, useContext } from 'react';
import { useLocation } from 'react-router-dom';
import Navbar from '../Navbar';
import { Typography, Table, Button, Tag, Modal, Form, Input, message, Switch, Checkbox, Select } from 'antd';
import { EnvironmentOutlined } from '@ant-design/icons';
import { fetchSitesByOrganizationId, createSite, linkOrganizationSite, updateSite, getOrganizationSitesBySiteId, deleteOrganizationSitesBySiteId, getAllOrganizations } from '../../Services'; // Import createSite and updateSite API functions
import { AuthContext } from '../Login/AuthProvider';
import { Role } from '../../enums';

const ManageSites = () => {
  const { auth } = useContext(AuthContext);
  const { Title, Text } = Typography;
  const location = useLocation();
  const { organizationId } = location.state || { organizationId: null };

  const [dataSource, setDataSource] = useState<any[]>([]);
  const [loading, setLoading] = useState<boolean>(false);
  const [isModalVisible, setIsModalVisible] = useState<boolean>(false); // Modal visibility state
  const [isEditMode, setIsEditMode] = useState<boolean>(false); // To track if it's edit mode
  const [editingSiteId, setEditingSiteId] = useState<number | null>(null); // Store the site being edited
  const [isActiveCheck, setIsActiveCheck] = useState<boolean>(true);
  const [isStatusModalVisible, setIsStatusModalVisible] = useState<boolean>(false);
  const [isMultippleOrganizationChecked, setIsMultippleOrganizationChecked] = useState<boolean>(false);
  const [allOrganizations, setAllOrganizations] = useState<any[]>([]);
  const [form] = Form.useForm();

  const loadSites = async () => {
    try {
      if (organizationId) {
        setLoading(true);
        const sitesData = await fetchSitesByOrganizationId(organizationId);
        if (sitesData) {
          const formattedSites = sitesData.map((site: any) => ({
            key: site.Site.id,
            alias: site.Nodes?.length > 0 ? site.Nodes[0].alias : 'N/A',
            name: site.Site.name,
            location: site.Site.location,
            activeUsers: site.Organization.Users?.length || 0,
            nodes: site.Site.Nodes?.length || 0,
            organizationname: site.Organization.name,
            status: site.Site.isActive,
          }));
          setDataSource(formattedSites);
        }
        setLoading(false);
      }
    } catch (e) {
      console.log(e);
    }

  };

  const laodAllOrganizations = async () => {
    try {
      const fetchedOrganizations = await getAllOrganizations();
      const filteredOrganizations = fetchedOrganizations.filter((org: any) => org.id !== organizationId);
      setAllOrganizations(filteredOrganizations);
    } catch (e) {
      console.log(e);
    }
  };

  useEffect(() => {
    loadSites();
    laodAllOrganizations();
  }, [organizationId]);

  const columns = [
    { title: 'NAME', dataIndex: 'name', key: 'name', sorter: (a: any, b: any) => a.name.localeCompare(b.name) },
    { title: 'LOCATION', dataIndex: 'location', key: 'location', sorter: (a: any, b: any) => a.location.localeCompare(b.location) },
    { title: 'ACTIVE USERS', dataIndex: 'activeUsers', key: 'activeUsers', sorter: (a: any, b: any) => a.activeUsers - b.nameactiveUsers },
    { title: 'NODES', dataIndex: 'nodes', key: 'nodes', sorter: (a: any, b: any) => a.nodes - b.nodes },
    { title: 'ORGANIZATION', dataIndex: 'organizationname', key: 'organizationname', sorter: (a: any, b: any) => a.organizationname.localeCompare(b.organizationname) },
    {
      title: 'STATUS',
      dataIndex: 'status',
      key: 'status',
      width: 250,  // Set a fixed width for the status column
      render: (status: boolean, record: any) => (
        <>
          <div style={{ display: 'flex', gap: '10px', alignItems: 'center' }}>
            {(auth.role == Role.SUPER_ADMIN || auth.role == Role.ADMIN) ? ( // Replace this with your custom condition
              <Switch
                checked={status}
                onChange={(check: boolean) => handleStatusChange(check, record.key)}
              />
            ) : null}
            <Tag
              color={status ? 'green' : 'red'}
              style={{
                width: '80px',  // Set a fixed width for the tag
                textAlign: 'center',  // Center the text inside the tag
              }}
            >
              {status ? "Active" : "Inactive"}
            </Tag>
          </div>
        </>
      ),
    },


    ...((auth.role == Role.SUPER_ADMIN || auth.role == Role.ADMIN)
      ? [
        {
          title: 'ACTIONS', // Updated column to include both Edit and Delete buttons
          key: 'actions',
          render: (record: any) => (
            <Button type="default" onClick={async () => await showModal("edit", record.key)}>
              Edit
            </Button>
          ),
        },
      ]
      : [])

  ];



  // Open Modal for Creating
  const showModal = async (key: string, id?: number) => {
    setIsModalVisible(true);
    setIsEditMode(key === "add" ? false : true);

    if (key === "add") {
      await laodAllOrganizations();
      form.resetFields();
    }
    else if (key === "edit" && id !== undefined) {
      setEditingSiteId(id);

      const newDataSource = [...dataSource];
      const index: number = newDataSource.findIndex((item: any) => item.key === id);
      if (index !== -1) {

        const editSiteLinkedOrganizationsResponse = await getOrganizationSitesBySiteId(id);
        await laodAllOrganizations();

        if (editSiteLinkedOrganizationsResponse) {

          const filteredOrganizations = editSiteLinkedOrganizationsResponse.filter((item: any) => item.organizationId !== organizationId);

          if (filteredOrganizations.length > 0) {
            setIsMultippleOrganizationChecked(true);

            form.setFieldsValue({
              siteName: newDataSource[index].name,
              siteLocation: newDataSource[index].location,
              organizationNames: filteredOrganizations.map((item: any) => item.organizationId)
            });
          } else {

            form.setFieldsValue({
              siteName: newDataSource[index].name,
              siteLocation: newDataSource[index].location,
            });

          }
        }

      }
    }

  };

  // Close Modal
  const handleCancel = () => {
    setEditingSiteId(null);
    setIsMultippleOrganizationChecked(false);
    setAllOrganizations([]);
    form.resetFields();
    setIsModalVisible(false);
  };


  const handleOk = async () => {
    try {
      const values = await form.validateFields(); // Validate form fields

      if (isEditMode && editingSiteId) {
        // If in edit mode, call updateSite API
        const updatedSite = {
          name: values.siteName,
          location: values.siteLocation,
        };
        const result = await updateSite(editingSiteId, updatedSite);

        if (result) {
          const { message: updatedSiteMessage, status } = result;

          if (status === 409) {
            message.error(updatedSiteMessage);
            return;
          }

          const otherOrgIds: number[] = isMultippleOrganizationChecked === true && values.organizationNames.length > 0 ? values.organizationNames : [];
          const linkedSiteIds: number[] = [...otherOrgIds, organizationId];

          const deleteOrgsBySiteIdStatus = await deleteOrganizationSitesBySiteId(editingSiteId);
          if (deleteOrgsBySiteIdStatus === 200) {
            // Link the newly created site to multiple organizations
            const linkResponses = await Promise.all(
              linkedSiteIds.map((orgId: number) => {
                return linkOrganizationSite({
                  organizationId: orgId,
                  siteId: editingSiteId,
                });
              })
            );

            if (linkResponses) {
              message.success(updatedSiteMessage);
              handleCancel();
              loadSites();
              laodAllOrganizations();
            }


          }

        }
      } else {
        // If not in edit mode, create a new site
        const newSiteData = {
          name: values.siteName,
          organizationId: organizationId,
          location: values.siteLocation,
          isActive: true,
        };

        const result = await createSite(newSiteData);

        if (result) {
          const { createdSite, message: createdSiteMessage, status } = result;

          if (status === 409) {
            message.error(createdSiteMessage);
            return;
          }
          const otherOrgIds: number[] = isMultippleOrganizationChecked === true && values.organizationNames.length > 0 ? values.organizationNames : [];
          const linkedSiteIds: number[] = [...otherOrgIds, organizationId];

          // Link the newly created site to multiple organizations
          const linkResponses = await Promise.all(
            linkedSiteIds.map((orgId: number) => {
              return linkOrganizationSite({
                organizationId: orgId,
                siteId: createdSite.id,
              });
            })
          );

          if (linkResponses) {
            message.success(createdSiteMessage);
            handleCancel();
            loadSites();
            laodAllOrganizations();
          }
        }
      }
    } catch (errorInfo) {
      console.error('Failed to submit:', errorInfo);
    }
  };


  const handleStatusChange = async (checked: boolean, id: number) => {

    if (checked === false) {
      setIsActiveCheck(checked);
      setEditingSiteId(id);
      setIsStatusModalVisible(true);
    }
    else {
      const updatedStatus = {
        isActive: checked,
      };
      const result = await updateSite(id, updatedStatus);
      if (result) {
        const { message: updatedSiteMessage } = result;
        message.success(updatedSiteMessage, 3);
        loadSites();
      }
    }



  };

  const handleStatusUpdate = async () => {
    try {
      if (editingSiteId) {
        const updatedStatus = {
          isActive: isActiveCheck,
        };
        const result = await updateSite(editingSiteId, updatedStatus);
        if (result) {
          const { message: updatedSiteMessage } = result;
          message.success(updatedSiteMessage, 3)
          handleStatusCancel();
          loadSites();
        }
      }

    } catch (e) {
      console.log(e);
    }
  };

  const handleStatusCancel = () => {
    setIsStatusModalVisible(false);
    setIsActiveCheck(true);
    setEditingSiteId(null);
  }


  return (
    <>
      <div className='h-[10vh]'>
        <Navbar page={'manageSites'} />
      </div>
      <div className='h-[190vh] p-5'>
        <Title style={{ textAlign: 'center', marginBottom: 20, fontSize: 20 }}>
          ADMIN SETTINGS
        </Title>
        <div style={{ display: 'flex', alignItems: 'center', marginLeft: 40, marginBottom: 20 }}>
          <EnvironmentOutlined style={{ fontSize: 60, marginRight: 10, color: 'white' }} />
          <Title level={3} style={{ fontSize: 20, marginBottom: 0 }}>
            SITES
          </Title>
        </div>
        <Table
          style={{ paddingLeft: 40, paddingRight: 40 }}
          dataSource={dataSource}
          columns={columns}
          pagination={false}
          loading={loading}
          bordered={true}
          footer={() => (
            (auth.role === Role.ADMIN || auth.role === Role.SUPER_ADMIN) &&
            <Button type="dashed" onClick={async () => await showModal("add")}>
              + Add New Site
            </Button>
          )}
        />
      </div>

      {/* Add/Edit Site Modal */}
      <Modal
        title={isEditMode ? "Edit Site" : "Add New Site"} // Dynamic title based on mode
        visible={isModalVisible}
        onOk={handleOk}
        onCancel={handleCancel}
        okText={isEditMode ? "Update" : "Add"} // Dynamic button text based on mode
        cancelText="Cancel"
      >
        <Form form={form} layout="vertical" name="add-site-form">
          {/* Site Name Field */}
          <Form.Item
            label="Site Name"
            name="siteName"
            rules={[{ required: true, message: 'Please enter the site name!' }]}
          >
            <Input placeholder="Enter site name" />
          </Form.Item>

          <Form.Item
            label="Site Location"
            name="siteLocation"
            rules={[{ required: true, message: 'Please enter the site location!' }]}
          >
            <Input placeholder="Enter site location" />
          </Form.Item>

          {/* <Label></Label> */}
          <Text>Assign to multiple organizations </Text>
          <Checkbox checked={isMultippleOrganizationChecked} onChange={(e) => setIsMultippleOrganizationChecked(!isMultippleOrganizationChecked)} />

          {/* {
            isMultippleOrganizationChecked === true ?

              <Form.Item
                label="Select Organizations"
                name="organizationNames"
                rules={[{ required: false, message: 'Please select organizations!' }]}
              >

                <Select
                  mode="multiple"
                  options={allOrganizations.map((item) => ({ label: item.name, value: item.id }))}
                  allowClear={true}
                />

              </Form.Item>
              : <></>


          } */}

          {
            isMultippleOrganizationChecked && (
              <Form.Item
                label="Select Organizations"
                name="organizationNames"
                rules={[{ required: false, message: 'Please select organizations!' }]}
              >
                <Select
                  mode="multiple"
                  options={allOrganizations.map((item) => ({
                    label: item.name,
                    value: item.id,
                  }))}
                  allowClear={true}
                />
              </Form.Item>
            )
          }

        </Form>
      </Modal >


      <Modal
        title={"Deactivate Site"}
        visible={isStatusModalVisible}
        onCancel={handleStatusCancel}
        cancelText={"No"}
        onOk={handleStatusUpdate}
        okText={"Yes"}
      >
        <br />
        Are you sure you want to Deactivate the Site?
      </Modal >
    </>
  );
};

export default ManageSites;
