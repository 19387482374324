import { Button, Form, Input } from 'antd';
import { useRef } from 'react';

const PsdRangeSettings = ({ minValue, maxValue, setMinAndMaxValues }: any) => {
  const [form] = Form.useForm();


  const min = useRef(minValue);
  const max = useRef(maxValue);

  const validateMinimumValue = (_rule: any, value: any) => {
    const parsedValue = parseFloat(value);  // Parse value as float
    if (parsedValue < min.current) {
      return Promise.reject(new Error(`Minimum value cannot be less than ${min}`));
    }
    if (parsedValue > max.current) {
      return Promise.reject(new Error(`Minimum value cannot be greater than ${max}`));
    }
    return Promise.resolve();
  };

  const validateMaximumValue = (_rule: any, value: any) => {
    const parsedValue = parseFloat(value);  // Parse value as float
    if (parsedValue < min.current) {
      return Promise.reject(new Error(`Maximum value cannot be less than ${min}`));
    }
    if (parsedValue > max.current) {
      return Promise.reject(new Error(`Maximum value cannot be greater than ${max}`));
    }
    return Promise.resolve();
  };

  const validateRange = (_rule: any, value: any) => {
    const minimumValue = parseFloat(form.getFieldValue('minimumValue'));
    const parsedValue = parseFloat(value);  // Parse value as float
    if (minimumValue !== undefined && parsedValue < minimumValue) {
      return Promise.reject(new Error('Maximum value must be greater than or equal to minimum value'));
    }
    return Promise.resolve();
  };


  const onSave = (values: Record<string, string>) => {
    const minimum: number = values.minimumValue ? parseFloat(values.minimumValue) : min.current;
    const maximum: number = values.maximumValue ? parseFloat(values.maximumValue) : max.current;
    setMinAndMaxValues(minimum, maximum);

  };

  const onReset = () => {
    setMinAndMaxValues(min.current, max.current);
    form.resetFields();
  };

  return (
    <div>
      <Form form={form} onFinish={onSave} onReset={onReset}>

        <Form.Item
          name="minimumValue"
          rules={[
            { validator: validateMinimumValue }
          ]}
        >
          <Input placeholder={`Enter minimum value between ${min.current} and ${max.current}`} />
        </Form.Item>

        <Form.Item
          name="maximumValue"
          rules={[
            { validator: validateMaximumValue },
            { validator: validateRange }
          ]}
        >
          <Input placeholder={`Enter maximum value between ${min.current} and ${max.current}`} />
        </Form.Item>

        <div style={{ display: 'flex', gap: '10px' }}>
          <Form.Item>
            <Button type="default" htmlType="submit" style={{ width: 200 }}>
              Apply
            </Button>
          </Form.Item>

          <Form.Item>
            <Button type="default" htmlType="reset" style={{ width: 200 }}>
              Reset
            </Button>
          </Form.Item>
        </div>
      </Form>
    </div>
  );
}

export default PsdRangeSettings;
