import React, { useState } from "react";
import { Button, Checkbox, Radio, Select } from "antd";
import ExportModal from "./ExportModal";
import ScatterUtilization from "./ScatterUtilization";
import UtilizationVisualizer from "./UtilizationVisualizer";
import type { CheckboxProps } from "antd";

const Utilization = () => {
  const [isModalOpen, setisModalOpen] = useState(false);
  const [type, setType] = useState("CSV");
  const [graphType, setGraphType] = useState("scatter"); // visualizer | scatter
  const [checkFCC, setCheckFCC] = useState(false);
  const [occupancyFilter, setOccupancyFilter] = useState("0");
  const exportModalHandler = (type: string) => {
    console.log("type =>", type);
    setType(type);
    setisModalOpen(true);
  };

  const onChangeFccHandler: CheckboxProps["onChange"] = (e) => {
    console.log(`checked = ${e.target.checked}`);
    setCheckFCC(e.target.checked);
  };

  const occupancyHandler = (val: any) => {
    setOccupancyFilter(val);
  };

  return (
    <div className="w-screen h-screen bg-[#2c2c2c] text-[#9b9a9a]">
      <div className="grid grid-cols-12 h-screen pt-10">
        <div id="svg-data" className="col-span-9 pt-10">
          <div className="flex flex-col gap-2 px-8 py-4">
            <div className="flex items-center gap-4">
              <div>Rec ID: 525</div>
              <div>Observation Time: 15 minutes</div>
            </div>
            <div className="flex items-center gap-4">
              <div>Start Date: 10/30/2024</div>
              <div>Start Time: 22:35:15</div>
              <div>Stop Date: 10/31/2024</div>
              <div>Stop Time: 23:50:30</div>
            </div>
            <div className="flex items-center gap-4">
              <div>Sample Rate: 40MSPS</div>
              <div>Bandwidth: 40MHZ</div>
              <div>NFFT: 16384</div>
            </div>
          </div>
          {graphType === "scatter" ? (
            <ScatterUtilization
              occupancyFilter={occupancyFilter}
              checkFCC={checkFCC}
            />
          ) : (
            <UtilizationVisualizer />
          )}
          <div className="py-4 px-8">
            <Checkbox onChange={onChangeFccHandler}>
              {" "}
              Match FCC License*
            </Checkbox>
          </div>
        </div>
        <div className="rounded col-span-3 flex flex-col gap-2 py-12 px-12 w-100">
          <div className="h-full flex flex-col gap-16 justify-between">
            <div className="flex flex-col gap-4">
              <Button
                size="large"
                style={
                  graphType !== "scatter"
                    ? { backgroundColor: "white", color: "black" }
                    : {}
                }
                onClick={() => setGraphType("scatter")}
              >
                Scatter Utilization
              </Button>
              <Button
                style={
                  graphType === "scatter"
                    ? { backgroundColor: "white", color: "black" }
                    : {}
                }
                size="large"
                onClick={() => setGraphType("visualizer")}
              >
                Utilization Visualizer
              </Button>
            </div>
            <div className="flex flex-col flex-1 gap-4">
              <div className="flex flex-col gap-2">
                <label>Occupancy</label>
                <Select
                  size="large"
                  placeholder="Occupancy"
                  defaultValue={"0"}
                  onChange={occupancyHandler}
                  options={[
                    { label: "Greater than 0%", value: "0" },
                    { label: "Greater than 20%", value: "20" },
                    { label: "Greater than 30%", value: "30" },
                    { label: "Greater than 40%", value: "40" },
                    { label: "Greater than 50%", value: "50" },
                    { label: "Greater than 60%", value: "60" },
                    { label: "Greater than 70%", value: "70" },
                    { label: "Greater than 80%", value: "80" },
                    { label: "Greater than 90%", value: "90" },
                  ]}
                />
              </div>

              <div className="flex flex-col gap-2">
                <div>Power Type</div>
                <Radio.Group defaultValue="peak-power" size="large">
                  <Radio.Button value="peak-power">Peak Power</Radio.Button>
                  <Radio.Button value="average-power">
                    Average Power
                  </Radio.Button>
                </Radio.Group>
              </div>
              <div className="flex flex-col gap-2">
                <label>Power (dBm)</label>
                <Select
                  size="large"
                  placeholder="Power (dBm)"
                  defaultValue={"Greater than -120"}
                  options={[
                    { label: "Less than -120", value: "Less than 120" },
                    { label: "Less than -110", value: "Less than 110" },
                    { label: "Less than -100", value: "Less than 100" },
                    { label: "Less than -90", value: "Less than 90" },
                    { label: "Less than -80", value: "Less than 80" },
                    { label: "Less than -70", value: "Less than 70" },
                    { label: "Less than -60", value: "Less than 60" },
                    { label: "Less than -50", value: "Less than 50" },
                    { label: "Less than -40", value: "Less than 40" },
                    { label: "Less than -30", value: "Less than 30" },
                    { label: "Less than -20", value: "Less than 20" },
                    { label: "Less than -10", value: "Less than 10" },
                    { label: "Less than -0", value: "Less than 0" },
                  ]}
                />
              </div>
            </div>
            <div className="flex flex-col gap-4">
              <Button
                size="large"
                style={{ backgroundColor: "white", color: "black" }}
                onClick={() => exportModalHandler("CSV")}
              >
                Export as CSV
              </Button>
              <Button
                size="large"
                style={{ backgroundColor: "white", color: "black" }}
                onClick={() => exportModalHandler("PDF")}
              >
                Export as PDF
              </Button>
            </div>
          </div>
        </div>
      </div>
      <ExportModal
        isExportModalVisible={isModalOpen}
        setIsExportModalVisible={setisModalOpen}
        type={type}
        center={true}
      />
    </div>
  );
};

export default Utilization;
